import { createI18n } from "vue-i18n";

const messages = {
  en: {
    profile: "Profile",
    myProfile: "My Profile",
    dashboard: "Dashboard",
    documents: "Documents",
    documentation: "Documentation",
    administration: "Administration",
    activity: "Activity",
    notification: "Notifications",
    logout: "Logout",
    chat: "Chat",
    example: "Example",
    exampleLink: "Example Link",
    megaMenu: "Mega Menu",
    settings: "Settings",
    day: "Day",
    today: "Today",
    yesterday: "Yesterday",
    email: "E-mail",
    password: "Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    role: "Role",
    visibility: "Visibility",
    visibilityPermissions: "Visibility Permissions",
    availablePermissions: "Available Permissions",
    selectedPermissions: "Selected Permissions",
    twofacode: "2FA Code",
    address: "Address",
    title: "Title",
    date: "Date",
    birthDate: "Birth Date",
    startDate: "Start Date",
    startDateShortForm: "Start Date",
    endDate: "End Date",
    shortCode: "Short Code",
    phone: "Phone Number",
    gsmPhone: "GSM Phone Number",
    officePhone: "Office Phone Number",
    extensionNumber: "Extension Number",
    location: "Location",
    username: "Username",
    fullName: "Full Name",
    all: "All",
    pleaseWait: "Please wait...",
    messageOfTheDay: "Message of the day",
    favoriteMenus: "Favorite Menus",
    positionEnglish: "Position (English)",
    theme: "Theme",
    themeLight: "Light",
    themeDark: "Dark",
    themeSystem: "System",
    availableRoles: "Available Roles",
    selectedRoles: "Selected Roles",
    availableCustomers: "Available Customers",
    selectedCustomers: "Selected Customers",
    orderToQuoteRates: "Order / Quote Rates",
    selectAddress: "Select Address",
    addressHeading: "Address Heading",
    activityNumber: "Number Of Activities",
    nameAndLastName: "Name Surname",
    currentPassword: "Current Password",
    newPassword2: "New password again",
    updatePassword: "Update Password",
    userInfo: "User Information",
    two2Until: "2FA Valid Until",
    is2FAEnabled: "Is 2FA Enabled",
    projectName: "Project Name",
    projectContact: "Project Contacts",
    projectImportance: "Project Importance",
    projectSector: "Sector",
    projectSubSector: "Sub Sector",
    projectProductionDate: "Expected Mass Production Date",
    projectEau: "EAU",
    projectElt: "Expected Life Time",
    companyName: "Company Name",
    annual: "Annual",
    accountManager: "Account Manager",
    customerRepresentative: "Customer Representative",
    customerSpecialCode: "Customer Special Code",
    customerGroup: "Customer Group",
    showSimilarityResults: "Show Similarity Results",
    similarityResults: "Similarity Results",
    similarity: "Similarity",
    backlogInfo: "To Be Completed In The Current Year / Total",
    quoteCreator: "Quote Creator",
    productCode: "Product Code",
    productDescription: "Product Description",
    materialGroupCode: "Material Group Code",
    currentTitle: "Current Title",
    representative: "Representative",
    authorizedEmail: "Authorized Email",
    authorizedTitle: "Authorized Title",
    salesRepresentative: "Sales Representative",
    gender: "Gender",
    customerName: "Customer Name",
    customerLastName: "Customer Last Name",
    customerTitle: "Title",
    customerTitle2: "Customer Title",
    customerCommercialTitle: "Customer Commercial Title",
    description: "Description",
    additionalInformation: "Additional Information",
    fileAdd: "Add File",
    files: "Files",
    main: "Main",
    alternative: "Alternative",
    line: "Line",
    availableLines: "Available Lines",
    selectedLines: "Selected Lines",
    user: "User",
    word: "Word",
    definition: "Explanation",
    unit: "Unit",
    productsName: "Product Name",
    totalYearlyPotential: "Total Annual Potential",
    myReports: "My Reports",
    lastUpdateDate: "Last Update Date",
    rollingTotal: "Rolling Potential",
    totalLifeTime: "Life Time Revenue",
    noFiles: "No files found",
    noSharedFoldersOrFiles: "No shared folders or files found",
    noFilesThisFolder: "No files found in this folder",
    editNotes: "Note Is Being Edited",
    savedNotes: "Saved Notes",
    pickADay: "Pick a day",
    attachments: "Attachments",
    comments: "Comments",
    module: "Module",
    selectModule: "Select Module",
    activityType: "Activity Type",
    reference: "Reference",
    referenceNumber: "Reference Number",
    externalCode: "External Code",
    note: "Note",
    headline: "Headline",
    subject: "Content",
    subSubject: "Sub Content",
    taskAssignee: "Task Assignment",
    customer: "Customer",
    deliveryCustomer: "Delivery Customer",
    customerContact: "Customer Contact",
    contactInformation: "Contact Info",
    participants: "Attendees",
    category: "Category",
    importance: "Importance",
    discussedProjects: "Discussed Projects",
    relatedProjects: "Related Projects",
    projectsRelatives: "Related Projects",
    dateandTime: "Select Date and Time",
    selectDate: "Select Date",
    selectTime: "Select Time",
    delivery: "Delivery",
    incoterms: "Incoterms",
    budgetary: "Is Budgetary?",
    downloading: "Downloading...",
    selectYear: "Select Year",
    year: "Year",
    project: "Project",
    totalAnnualPotential: "Total Annual Potentıal",
    totalPotential: "Total Potentıal",
    rollingPotential: "Rollıng Potentıal",
    rollingExpectation: "Rolling Expectation",
    projectStatus: "Project Status",
    projectStage: "Project Stage",
    projectPhase: "Project Phase",
    productPhase: "Product Phase",
    phaseUpdatedDate: "Phase Updated Date",
    createdDate: "Created Date",
    logoCreatedDate: "Created Date (Logo)",
    createdBy: "Created By",
    cancelReason: " Cancel Reason",
    type: "Type",
    formType: "Form Type",
    updatedDate: "Updated Date",
    userUpdateDate: "User Update Date",
    is_register: "Registered?",
    is_secret: "Is Secret?",
    is_sample: "Sampled?",
    updaters: "Updaters",
    update: "Update",
    searchProductCode: "Search Product Code...",
    searchProduct: "Search Product",
    searchProjectNameAndProductCode: "Search Project Name...",
    manager: "Manager",
    normal: "Normal",
    contacts: "Contacts",
    contact: "Contact",
    sector: "Sector",
    selectFile: "Select File",
    preloadedFiles: "Preloaded Files",
    dropFile: "Drop file here or click to upload",
    selectUser: "Select User",
    search: "Search...",
    searchTag: "Search Tag...",
    select: "Select",
    selectSearchType: "Select Search Type",
    tag: "Tag",
    creatingTag: "Creating Tag...",
    alreadyExistTag: "Tag already exists.",
    typeAndPressEnter: "Type and press enter",
    typeAndPressEnterInfo: "Type and press ENTER to create a new tag.",
    you: "You",
    status: "Status",
    twoFAStatus: "2FA Status",
    selectStatus: "Select Status",
    customize: "Customize",
    product: "Product",
    pickADate: "Pick a Date",
    minOrderValue: "Min. Order Value",
    minOrderPrice: "Minimum Order Price",
    paymentTerm: "Payment Term",
    paymentType: "Payment Type",
    paymentPlan: "Payment Plan",
    invoicingType: "Invoicing Type",
    shipmentType: "Shipment Type",
    biba: "Biba",
    backlog: "Backlog",
    sidebarNew: "New",
    goal: "Goal",
    boxCount: "Box Count",
    salesAmount: "Sales Amount",
    amount: "Amount",
    quarterForecast: "Quarter Forecast",
    postCode: "Post Code",
    taxNumber: "Tax Number",
    taxOffice: "Tax Office",
    lineContact: "Line Contacts",
    prev: "Prev",
    next: "Next",
    loading: "Loading...",
    loadingNoDot: "Loading",
    loadMore: "Load More",
    loadLess: "Load Less",
    selected: "{count} Selected",
    happyBirthday: "Happy Birthday",
    listWiew: "List View",
    gridView: "Grid View",
    yes: "Yes",
    no: "No",
    lineName: "Line Name",
    validationMin: "Minimum {min} characters",
    validationMax: "Maximum {max} characters",
    quantity: "Quantity",
    qty: "QTY",
    currency: "Currency",
    price: "Price",
    buyPrice: "Buy Price",
    salesPrice: "Sales Price",
    listPrice: "List Price",
    sales: "Sales",
    marketing: "Marketing",
    lastNinetyDays: "Last 90 Days",
    lastOneYear: "Last 1 Year",
    reelQuote: "Reel",
    budgetaryQuote: "Budgetary",
    reealQuoteTotal: "Real Quote Total",
    orderAndRealQuoteRatio: "Order / Real Quote Ratio",
    orderOrQuote: "Order / Quote",
    realQuote90Days: "Real Quote (90 Days)",
    orderTotal: "Order Total",
    projectSummary: "Project Summary",
    newProjectAndSocketCount: "New Project / Socket Count",
    taxes: "Taxes",
    incomeTax: "Income Tax",
    additionalIncomeTax: "Additional Income Tax",
    selectProduct: "Select Product",
    selectedProducts: "Selected Products",
    selectLine: "Select Line",
    searchLine: "Search Line",
    searchUser: "Search User",
    expirationDate: "Expiration Date",
    pinCode: "Pin Code",
    setPinCode: "Set Pin Code or",
    generatePinCode: "Generate Pin Code",
    pinCodeInfo: "Please set a 4-character pin code",
    pleaseEnterPinCode: "Please enter your pin code",
    viewAll: "View All",
    fileName: "File Name",
    fileSize: "File Size",
    addToContact: "Add To Contact",
    cities: "Cities",
    share: "Share",
    creationDate: "Creation Date",
    last90DaysActivities: "Last 90 Days Activities",
    countOfVisitMonthly: "Count Of Visit Monthly",
    countOfVisitMonthlyTarget: "Count Of Visit Monthly Target",
    week: "Week {n}",
    isResolved: "Is Resolved?",
    isUserActionNeeded: "Action Needed?",
    createTicket: "Create Ticket",
    ticketInfo: "Report a Problem or Create a Request",
    ticketDetail: "Report/Request Detail",
    responseBy: "Response By",
    resolvedBy: "Resolved By",
    ticketDescription: "DESCRIPTION",
    // Quote
    quoteNo: "Quote No",
    quoteType: "Quote Type",
    quoteLanguage: "Quote Language",
    quoteSubjectTitle: "Quote Subject Title",
    quoteCurrency: "Quote Currency",
    customerRefCode: "Customer Ref. Code",
    deliveryType: "Delivery Type",
    deliveryTime: "Delivery Time",
    deliveryLocation: "Delivery Location",
    isBudgetary: "Is Budgetary?",
    quoteMessageContent: "Quote Message Content",
    standard: "Standard",
    special: "Special",
    advance: "Advance",
    otherTerms: "Other Terms",
    weekly: "Weekly",
    monthly: "Monthly",
    quarter: "Quarter",
    quarterly: "Quarterly",
    margin: "Margin",
    totalPrice: "Total Price",
    totalPrice2: "Total Price",
    commonPurchaseOffer: "Common Purchase Offer",
    attachedToProject: "Inconnected with Project",
    unregisteredProject: "Unregistered Project",
    stock: "Stock",
    actualStock: "Actual Stock",
    stockInformation: "Stock Information",
    inventoryInformation: "Inventory Information",
    formNo: "Form Number",
    searchFormNo: "Search Form Number",
    expoName: "Expo Name",
    conclusion: "Conclusion",
    media: "Media",
    countryAndCity: "Country/City",
    website: "Website",
    backPageWarningMsg:
      "Your changes will not be saved. Do you want to continue?",
    supplier: "Supplier",
    totalSalesTarget: "Total Sales Target",
    totalProfitTarget: "Total Profit Target",
    monthlySalesTarget: "Monthly Sales Target",
    monthlyProfitTarget: "Monthly Profit Target",
    lineSalesTarget: "Line Sales Target",
    lineProfitTarget: "Line Profit Target",
    profit: "Profit",
    lineCode: "Line Code",
    producerCode: "Producer Code",
    customerCode: "Customer Code",
    planDate: "Plan Date",
    startTime: "Start Time",
    endTime: "End Time",
    department: "Department",
    position: "Position",
    selectDepartment: "Select Department",
    lastVisitDate: "Last Visit Date",
    target: "Target",
    businessCard: "Business Card",
    turkishIdentityNumber: "TC Identity Number",
    deliveryInformation: "Delivery Information",
    reasonForDeletion:
      "If you sure, please enter your reason for deletion below",
    reasonForDeletionColumn: "Reason For Deletion",
    documentNr: "Document Nr",
    effectiveDate: "Effective Date",
    revisionNr: "Revision Nr",
    revisionDate: "Revision Date",
    pageNr: "Page Nr",
    red: "Red",
    orange: "Orange",
    green: "Green",
    total: "Total",
    privateActivity: "Private Activity",
    saveAsImage: "Save as Image",
    partNumber: "Part Number",
    internalNumber: "Internal Number",
    customerPartNumber: "Customer Part Number",
    purchaseOrder: "Purchase Order",
    purchaseOrderDontNeedMessage:
      "No orders will be placed with the manufacturer. The product will be sent from stock.",
    deliverToCustomer: "Deliver To Customer",
    processDate: "Process Date",
    expenseFormNumber: "Expense Form Number",
    typeOfExpense: "Type of Expense",
    cost: "Cost",
    image: "Image",
    expotag: "Expo Tag",
    poNumber: "PO Number",
    ytdSales: "YTD Sales",
    ytdBooking: "YTD Booking",
    ytdOrder: "YTD Order",
    searchAndSelectProduct: "Search and Select Product",
    elkStock: "ELK Stock",
    sbStock: "SB Stock",
    totalStock: "Total Stock",
    elkAverageUnitPrice: "ELK Avg. Unit Price",
    sbAverageUnitPrice: "SB Avg. Unit Price",
    avgUnitPrice: "Avg. Unit Price",
    priceLists: "Price Lists",
    analysis: "Analysis",
    averageUnitPrice: "Average Unit Price",
    totalAveragePrice: "Total Average Price",
    expiryDate: "Expiry Date",
    subcontractor: "Subcontractor",
    quoteDate: "Quote Date",
    isAvailable: "Is Available",
    unitPrice: "Unit Price",
    moq: "MOQ",
    mrq: "MRQ",
    milspecCode: "Milspec Code",
    requiredProductErrorMsg:
      "Please fill in the required fields ( * ) in the product table completely and make sure that the product information is correct.",
    species: "Type",
    lastWorkingHour: "Last Working Hour",
    nextWorkingHour: "Next Working Hour",
    orderNumber: "Order Number",
    orderInfo: "Open Orders",
    requestDate: "Request Date",
    guessDeliveryDate: "Guess Delivery Date",
    priceAnalysis: "Price Analysis",
    usePrice: "Use Price",
    waybillInfo: "Waybill Information",
    unitPurchasePrice: "Unit Purc. Price",
    unitSellingPrice: "Unit Sell Price",
    unitCost: "Unit Cost",
    salesTargetFormatMustUsd: "*Sales formats must be written in kUSD.",
    changeMonthlySalesTarget: "Montly Sales Target changed.",
    isResponsible: "Is Responsible?",
    isProjectTracking: "Is there a Project Tracking?",
    kpiCustomer: "KPI Customer",
    kpiLines: "KPI Lines",
    otp: "OTP",
    weeklyRequiredVisitCount: "Weekly Visit Target",
    weeklyRequiredVisitCountSales: "Yearly Project Target",
    userSpecificSettings: "User Specific Settings",
    kpiLineResponsibility: "Kpi Line Responsibility",
    realizationValue: "Realization Value",
    realizationScore: "Realization Score",
    weightedRealizationScore: "Weighted Realization Score",
    activityCategoryResponsibility: "Activity Category Responsibility",
    faxNumber: "Fax Number",
    invoiceAdress: "Invoice Adress",
    shippingAdress: "Shipping Adress",
    soleProprietorship: "Sole Proprietorship",
    taxNoOrTcNo: "Tax No or TC No",
    currencyType: "Currency Type",
    salesCurrency: "Sales Currency",
    projectFilters: "Project Filters",
    socketFilters: "Socket Filters",
    purchaseAuthority: "Purchase Authority",
    accountingAndFinanceOfficer: "Accounting/Finance Officer",
    rank: "Area of ​​Responsibility",
    country: "Country",
    province: "Province",
    district: "District",
    neighborhood: "Neighborhood",
    accountingAndFinanceOfficerEmail: "Accounting/Finance Agent Email",
    accountingAgentEmail: "Accounting Agent Email",
    purchasingAgentEmail: "Purchasing Agent Email",
    approvedBy: "Approved By",
    totalLifePotential: "Total Life Potential",
    customerCodeInfo:
      "The customer code will be determined again after approval. Ignore it until the customer is created.",
    kepAdress: "Kep Adress",
    shippingAdressControl: "Shipping address and invoice address are the same.",
    tradingGroup: "Trading Group",
    riskLimit: "Risk Limit",
    trackGroup: "Track Group",
    trackLotNumber: "Track Lot Number",
    trackSerialNumber: "Track Serial Number",
    specode: "Specode",
    sectorGroup: "Sector Information",
    estore: "E - Store",
    other: "Other",
    taxPlate: "Tax Plate",
    signatureCircular: "Signature Circular",
    tradeRegistryGazette: "Trade Registry Gazette",
    orderSupplier: "Order Supplier",
    newProduct: "New Product",
    serialNumber: "Serial Number",
    requestedQty: "Requested Qty.",
    fiveYearPotential: "Five Year Potential",
    currentYearPotential: "Current Year Potential",
    currentYearSalesExpectation: "Current Year Sales Exp.",
    potential: "Potential",
    salesExpectation: "Sales Expectation",
    yearlySalesTarget: "Yearly Sales Target",
    yearlySales: "Yearly Sales",
    taskType: "Task Type",
    clickToSeeMore: "Click To See More",
    lastUpdate: "Last Update",
    characterLeft: "character left",
    nothingEnhanced: "There's nothing enhanced.",
    changedMadeFollovs: "The changes made are as follows;",
    customerSegment: "Customer Segment",
    lineGroup: "Line Group",
    socket: "Socket",
    monthlySales: "Monthly Sales",
    monthlyOrder: "Monthly Order",
    customerRequestDate: "Customer Request Date",
    shippingCountry: "Shipping Country",
    deliveryAgent: "Delivery Agent",
    numberOfQuoteGiven: "Number Of Quote Given",
    amountOfQuoteGiven: "Amount Of Quote Given",
    amountOfOrdersReceived: "Amount Of Orders Received",
    orderConversionRate: "Order Conversion Rate",
    numberOfQuote: "Number Of Quote",
    quoteValue: "Quote Value",
    /** Menu */
    menuitems: {
      mainPage: "Main Page",
      dashboard: "Dashboard",
      activities: "Activities",
      activitiesApp: "Activities Application",
      quotes: "Quotes",
      quotesApp: "Quotes Application",
      projects: "Projects",
      projectsApp: "Projects Application",
      users: "Users",
      logout: "Sign Out",
      notification: "Notification",
      customers: "Customers",
      products: "Products",
      lines: "Lines",
      sync: "Synchronization",
      customerSync: "Customer Synchronization",
      productSync: "Product Synchronization",
      list: "Lists",
      operations: "Operations",
      calculator: "Calculator",
      tools: "Tools",
      logistics: "Logistics",
      freeZoneOperations: "Free Zone Operations",
      purchaseOrders: "(SB) Purchase Orders",
    },
    months: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    days: {
      short: {
        monday: "Mon",
        tuesday: "Tue",
        wednesday: "Wed",
        thursday: "Thu",
        friday: "Fri",
        saturday: "Sat",
        sunday: "Sun",
      },
    },
    /** Login Page */
    signIn: {
      title: "Sign In",
      introText: "Welcome back to EmpaDigital!",
      button: "Sign In",
      sendAgainCode: "Send Code",
      otpInfo:
        "Please enter the code sent to your e-mail address. If the code has not been received, you can try again by clicking the 'Send Code' button within {second} seconds.",
      otpInfoNowSendCode:
        "Please enter the code sent to your e-mail address. If the code has not been received, you can try again by clicking the 'Send Code' button.",
      newCodeSendedMessage:
        "New code has been sent. Please check your mailbox.",
    },
    /** Forgot Password? */
    forgotPassword: {
      title: "Forgot Password",
      introText: "Enter your email to reset your password.",
      successMsg:
        "Your request has been received successfully. Please check your e-mail.",
    },
    /**  Reset Password */
    resetPassword: {
      title: "Reset Password",
      infoText: "Welcome to Empadigital. Please set a new password.",
      button: "Reset Password",
      successMsg:
        "Password is successfully changed! Please login with your new password.",
    },
    //** User Menu & 2FA */
    usermenu: {
      accountSettings: {
        title: "Account Settings",
        userInformation: "User Information",
        userName: "User Name",
        firstName: "First Name",
        lastName: "Last Name",
        gsmNumbber: "GSM Number",
        photo: "Photo",
        emailAddress: "E-mail Address",
        passwordUpdate: "Password Update",
        updateSuccessMsg: "User information updated successfully.",
        passwordUpdateSuccessMsg:
          "Password updated. Please login with your new password.",
      },
      twoFaDevices: {
        title: "My 2FA Devices",
        devicename: "Device Name",
        deviceStatus: "Device Status",
        applicationLink: "Application Links",
        clickStore: "Click for the application link!",
        deviceInfo: "Device Information",
        pincode: "Pin Code",
        message: {
          firstinfo:
            "You can use the 2FA feature with your smartphone. Every time you log in to EmpaDigital, you must use the instant code generated through the 2FA application. If you do not have access to your smartphone, you cannot log in to EmpaDigital. For questions about 2FA processes, please consult your administrator. You can install the Google Authenticator application on your smartphone using the links below or using QR codes.",
          secondinfo:
            "You can install the Google Authenticator application on your smartphone using the links below or using QR codes.",
          thirdinfo:
            "After installing the application, you must write the name you want to give to your device and click the 'Start' button.",
          fourthinfo:
            "You should scan the QR code below into the Google Authenticator app you have installed on your phone. Then, you must enter a code that the application will generate in the field below. When you complete the process, your 2FA device will be activated and you will need to log in to EmpaDigital again.",
        },
        successMsg:
          "Your new 2FA device has been successfully registered. Please login again.",
        delete: {
          modalTitle: "Delete My 2FA Device",
          warningMsg: "Are you sure you want to delete your 2FA device?",
          successMsg:
            "Your 2FA device has been deleted successfully. You have 24 hours to add a new 2FA device.",
        },
      },
      businessCard: {
        title: "My Business Card",
        qrcode: "Business Card QR Code",
      },
      createPermissionForm: "Create Permit Form",
      tickets: "Tickets",
      createAnnouncement: "Create Announcement",
    },
    /** Notifications */
    notifications: {
      title: "Notifications",
      myNotifications: "My Notifications",
      noNotificationsMessage: "No notifications yet",
      markAsRead: "Marked As Read!",
      markAllInRead: "Mark All In Read",
      unread: "Unread",
      read: "Read",
      readStatus: "Read Status",
      permissionMessage:
        "Please give permission from the notifications tool next to the address bar above to receive notifications.",
    },
    /** Dashboard */
    dashboards: {
      myDashboards: "My Dashboards",
      addNewDashboard: "Add New Dashboard",
      noDataAvailable: "No data available.",
      modal: {
        subtext: "Please select the dashboard you want to view.",
      },
    },
    /** Activities */
    activities: {
      title: "Activities",
      newActivity: "New Activity",
      totalActivity: "Total Activity",
      addNewActivity: "Create New Activity",
      editActivity: "Edit Activity",
      createNote: "Create Note",
      createTask: "Create Task",
      searchActivityTag: "Search Activity Tag...",
      projectNotDiscuss: "The project was not discussed",
      notCompletedActivitiesWarningMsg:
        "You are currently seeing filtered activities with missing notes.",
      shortcuts: {
        today: "Today",
        yesterday: "Yesterday",
        aWeekAgo: "A Week Ago",
      },
      importanceOptions: {
        firstStar:
          "(All Empa Line team and the Customer's Sales Representative will be informed.)",
        secondStar:
          "(All Empa Line team, the Customer's Sales Representative and all managers will be informed.)",
        thirdStar:
          "(All Empa Line team, the Customer's Sales Representative, all managers and the entire Sales Team will be informed.) ",
      },
      backPage: {
        warningMsg:
          "Your changes will not be saved, are you sure you want to continue?",
      },
      add: {
        successMsg: "Created new activity.",
        customerActivities: "Customer activities",
      },
      edit: {
        successMsg: "Updated activity.",
      },
      delete: {
        modalTitle: "Delete Activity",
        warningMsg: "Are you sure you want to delete the activity?",
        successMsg: "Your activity deletion request has been received.",
      },
      notes: {
        infoText:
          "Please fill the form completely in order for it to be saved note.",
        noNotes: "There is no note.",
        infoTextAutosave:
          "Do not forget to enter a title for your notes to be saved automatically.",
      },
      task: {
        title: "Tasks",
        emptyTableText: "There is no task for this activity.",
        add: {
          successMsg: "New task created.",
        },
      },
      files: {
        delete: {
          modalTitle: "Delete Activity File",
          warningMsg: "Are you sure you want to delete the activity file?",
          successMsg: "File deleted.",
        },
      },
      noteSummary: {
        title: "Activity Note Summary",
      },
    },
    /** Quotes */
    quotes: {
      title: "Quotes",
      newQuote: "New Quote",
      addNewQuote: "Create New Quote",
      totalQuote: "Total Quote",
      totalQuoteAmount: "Total Quote Amount",
      requireInternalCode: "Is there a Customer Internal Product Code?",
      marginInfoText: "Margin Before Taxes and Charges",
      noProductErrorMsg:
        "Quote cannot be created without creating the quote product!",
      requiredProductErrorMsg:
        "Please fill in the required fields ( * ) in the product table completely and make sure that the product information is correct.",
      backPage: {
        warningMsg:
          "Your changes will not be saved, are you sure you want to continue?",
        productWarningMsg:
          "Verify that the product table includes products and that they are all properly saved.",
        noProductWarningMsg: "Please add products to the product table.",
      },
      viewingRevision: "Viewing Revision {revision}",
      quoteRevisions: "Quote Revisions",
      noQuoteRevisions: "There is no quote revision.",
      add: {
        successMsg: "New quote created.",
      },
      edit: {
        successMsg: "Updated quote.",
        editingQuote: "QUOTE IS BEING EDITED",
      },
      revision: {
        title: "Create Revision",
        successMsg: "New revision created.",
      },
      excel: {
        button: "Import Data from Excel",
        templateExcelDataButton: "Template for Importing Excel Data",
        clearTable: "Clear Table",
        excelDataNotFound: "Copied excel data not found.",
        copiedDataFromExcel: "Copied Data From Excel Found",
        copiedDataFromExcelMessage:
          "Do you want it automatically added to the product table?",
      },
      product: {
        delete: {
          modalTitle: "Delete Quote Product",
          warningMsg: "Are you sure you want to delete the quote product?",
          successMsg: "Quote product deleted.",
        },
        add: {
          successMsg: "New product created.",
        },
        edit: {
          successMsg: "Product updated.",
        },
      },
      task: {
        title: "Tasks",
        emptyTableText: "There is no task for this quote.",
        add: {
          successMsg: "New task created.",
        },
      },
    },
    /**Expo */
    expo: {
      title: "Expos",
      newExpo: "New Expo",
      addNewExpo: "Create New Expo",
      searchExpotitle: "Search Expo...",
      expoSummary: "Expo Summary",
      expoSummaryInfo: "Figures, sections, history, impressions etc.",
      expoMediaInfo: "Photos about the expo.",
      expoMedia: "Expo Photos",
      expoActivityRecords: "Activity Records",
      expoActivityRecordsInfo: "Interviews or stand visits.",
      withOurOwnSuppliers: "With Our Own Suppliers",
      withPotentialSuppliers: "With Potential Suppliers",
      withCompetitors: "With Competitors",
      withCustomers: "With Customers",
      addExpoPhoto: "Add Expo Photo",
      expoTagTooltipText:
        "It is a tag created to track the event in modules such as Activity, Project and Proposal. The tag created here will be selectable in the mentioned modules. For the tag naming, use a format according to the examples below.",
      tableHeader: {
        name: "Expo Name",
        location: "Expo Location",
        date: "Date",
        expoWebsite: "Website",
      },
      plan: {
        add: {
          modalTitle: "Add Plan",
          successMsg: "Created new plan.",
        },
        edit: {
          modalTitle: "Edit Plan",
          successMsg: "Expo plan updated.",
        },
      },
      add: {
        successMsg: "Created new expo.",
      },
      report: {
        downloadingReport: "Reports are downloading",
        deleteActivityRequestMsg: "Activity deletion request is waiting.",
        update: {
          successMsg: "Expo report updated.",
        },
        newActivityRecord: {
          successMsg: "New activity record & activity created.",
        },
        updateActivityRecord: {
          successMsg: "Activity record & activity updated.",
        },
        deleteReport: {
          modalTitle: "Delete Report",
          warningMsg: "Are you sure you want to delete the report?",
          successMsg: "Your report deletion request has been received.",
        },
        deleteNote: {
          modalTitle: "Delete Note",
          warningMsg: "The note will be delete. Do you approve this action?",
          successMsg: "Note deleted successfully.",
        },
      },
    },
    /** Projects */
    projects: {
      title: "Projects",
      newProject: "New Project",
      addNewProject: "Create New Project",
      totalProject: "Total Project",
      projectHistory: "Project History",
      noProjectHistory: "There is no project history.",
      noProjectActivityHistory: "There is no activity history.",
      noProjectSampleHistory: "There is no sample history.",
      setLostWarningMsg: "Set product stage as 'LOST'.",
      winRateTooltip:
        "Probability of your product being used in the design. 1-Lowest, 3-Highest.",
      sockets: "Sockets",
      fiveYearlyValues: "5 Yearly Values",
      activeSockets: "Active Sockets",
      addProject: {
        customer: "Customer",
        selectCustomer: "Select Customer",
        customerProject: "Customer owned projects",
      },
      add: {
        successMsg: "New project created.",
      },
      backPage: {
        warningMsg:
          "Your changes will not be saved, are you sure you want to continue?",
      },
      copyProject: {
        warningMsg:
          "You are about to copy the project. Do you want to continue?",
      },
      edit: {
        successMsg: "Project updated.",
        editingProject: " PROJECT IS BEING EDITED",
      },
      note: {
        add: { successMsg: "New note created." },
        edit: { successMsg: "Note updated." },
        delete: {
          modalTitle: "Delete Note",
          warningMsg: "The note will be delete. Do you approve this action?",
          successMsg: "Note deleted.",
        },
      },
      delete: {
        modalTitle: "Delete Project",
        warningMsg: "Are you sure you want to delete the project?",
        successMsg: "Your project deletion request has been received.",
      },
      product: {
        warningMsg:
          "In the project, there must be at least 1 product. Otherwise, you can send a delete request to the project.",
        delete: {
          modalTitle: "Delete Project Product",
          warningMsg: "Are you sure you want to delete the project product?",
          successMsg: "Project product deleted.",
        },
        add: {
          successMsg: "New product created.",
        },
        edit: {
          successMsg: "Product updated.",
        },
        note: {
          lostDescriptionMsg:
            "The reason for the product LOST is in the notes.",
          add: {
            modalTitle: "LOST Product",
            warningMsg:
              "Products that are set to LOST cannot be edited or deleted again.",
            infoLabel: "Enter the reason for placing {product} as LOST:",
            successMsg: "New note created.",
          },
        },
      },
      task: {
        title: "Tasks",
        emptyTableText: "There is no task for this project.",
        add: {
          successMsg: "New task created.",
        },
      },
      files: {
        delete: {
          modalTitle: "Delete Project File",
          warningMsg: "Are you sure you want to delete the project file?",
          successMsg: "File deleted.",
        },
      },
    },
    /** Customers */
    customers: {
      title: "Customers",
      view: "Customer Details",
      paymentReceipt: "Payment Receipt",
      customerSummary: "Customer Summary",
      visitSuggestions: "Visit Suggestions",
      issuesAwaitingAction: "Issues Awaiting Action",
      customerName: "Customer Name",
      addNewCustomer: "Create New Customer",
      favorite: "My Fallows",
      authoritytable: { warningMsg: "There is no customer authorization." },
      activitytable: { warningMsg: "There is no customer activity." },
      quotetable: { warningMsg: "There is no customer quote." },
      projectstable: { warningMsg: "There is no customer project." },
      newCustomerAuth: "New Customer Authority",
      addCustomer: {
        successMsg: "New customer created.",
      },
      followCustomer: {
        successMsg: "The customer is being followed.",
      },
      unfollowCustomer: {
        successMsg: "You don't follow the customer anymore.",
      },
      searchCustmer: "Search Customer",
      customerViewMessage: "Please select the customer you want to view.",
      noContact: "There is no contact information for the customer.",
      noAddress: "There is no address information for the customer.",
      customerCheckSimilarityMsg:
        "There are customers similar to the customer name you are trying to create. Could it be one of these?",
      customerContact: {
        exampleContactInfo: "Example Contact Information",
        pleaseContactInput:
          "Please fill in the information in the format provided, with only the first letter capitalized.",
        add: {
          modalTitle: "New Customer Contact",
          successMsg: "New customer contact created.",
        },
        edit: {
          modalTitle: "Edit Customer Contact",
          successMsg: "Customer contact updated.",
        },
        isActive: {
          modalTitle: "Edit Customer Contact",
          warningMsg:
            "Are you sure you want to make this contact active/passive?",
          successMsg: "Customer contact updated.",
        },
      },
      contacts: {
        add: {
          button: "Add Contact",
          modalTitle: "New Contact",
          successMsg: "New contact created.",
        },
        edit: {
          successMsg: "Contact updated.",
        },
      },
      address: {
        add: {
          modalTitle: "New Customer Address",
          successMsg: "New customer address created.",
        },
      },
      tooltip: {
        salesInfo:
          "Annual visit target set for the customer's salesperson. Only visits attended by the salesperson are counted.",
        marketingInfo:
          "Annual visit target set for the customer's marketing team. Only visits attended by the marketing team are counted.",
        ytdSalesInfo:
          "Amount of sales made since the beginning of the current year.",
        ytdBookingInfo:
          "Quantity of orders received since the beginning of the current year.",
        bibaInfo:
          "Billing + Backlog, sum of YTD Sales value and Backlog going into the current year.",
      },
    },
    products: {
      title: "Products",
      view: "Product Details",
      add: {
        modalTitle: "Create New Product",
        successMsg: "New product code created.",
      },
    },
    /** Lines */
    lines: {
      title: "Lines",
      view: "Lines Details",
      searchLine: "Search Line",
      lineViewMessage: "Please select the line you want to view.",
      noNote: "No line's notes found.",
      noAddress: "No line's address found.",
      noContact: "There is no contact information for the line.",
      noMinOrderValue: "There is no 'Min. Order Value' for the line.",
      paymentTerm: {
        edit: {
          successMsg: "Payment term updated.",
        },
      },
      shipmentType: {
        edit: {
          successMsg: "Shipment type updated.",
        },
      },
      minOrderValue: {
        edit: {
          successMsg: "Min. Order Value updated.",
        },
      },
      note: {
        lineNote: "Line Note",
        add: {
          button: "Add Note",
          successMsg: "Line note created.",
        },
        edit: {
          successMsg: "Line note updated.",
        },
      },
      address: {
        add: {
          button: "Add Address",
          modalTitle: "Add Line Address",
          successMsg: "New address created.",
        },
        edit: {
          modalTitle: "Edit Line Address",
          successMsg: "Address updated.",
        },
      },
      contacts: {
        add: {
          button: "Add Contact",
          modalTitle: "Add Line Contact",
          successMsg: "New contact created.",
        },
        isActive: {
          modalTitle: "Edit Line Contact",
          warningMsg:
            "Are you sure you want to make this contact active/passive?",
          successMsg: "Line contact updated.",
        },
        edit: {
          modalTitle: "Edit Line Contact",
          successMsg: "Line contact updated.",
        },
      },
    },
    /** Users */
    users: {
      title: "Users",
      newUser: "New User",
      addNewUser: "Add New User",
      editUser: "Edit User",
      profiles: {
        profile: "PROFILE",
        faeProfile: "FAE PROFILE",
        accManagerProfil: "ACC. MANAGER PROFILE",
      },
      add: {
        successMsg: "New user created.",
      },
      edit: {
        successMsg: "User updated.",
      },
      deactive: {
        modalTitle: "Disable User",
        warningMsg: "The user will be disabled. Do you approve this action?",
        successMsg: "User disabled.",
      },
      active: {
        modalTitle: "Activate User",
        warningMsg: "The user will be activate. Do you approve this action?",
        successMsg: "User activated.",
      },
      adminChangePassword: {
        modalTitle: "Change Users Password",
        warningMsg:
          "The users password will be change. Do you approve this action?",
        successMsg: "Users password changed.",
      },
      selectUserRoleWarningMsg:
        "Please select the user's role you want to view.",
      tabs: {
        general: "General",
        kpiMetrics: "KPI Metrics",
        kpiManagement: "KPI Management",
        kpiAssessment: "KPI Competence Assessment",
      },
      kpiCustomers: {
        kpiResponsibleWarningMsg:
          "This customer is the responsibility of '{user}' for the current KPI period. Are you sure you want to continue?",
      },
      kpiLines: {
        edit: {
          successMsg: "Kpi Lines list updated.",
        },
      },
      kpiMetrics: {
        emptyDataWarningMsg:
          "No suitable KPI criteria found. Please contact your administrator.",
      },
      kpiAssessment: {
        employeeAssesment: "Employee Assesment",
        managerAssesment: "Manager Assesment",
        employeeComment: "Employee Comment",
        managerComment: "Manager Comment",
        userTotalScore: "User Total Score",
        managerTotalScore: "Competency Performance Score",
        notFoundMessage:
          "No evaluation found. You may have selected an old date or an evaluation time that is not yet due.",
        half1: "H1",
        endOfTheYear: "End Of The Year",
      },
    },
    userRoles: {
      ROLE_FAE: "FAE",
      ROLE_ACCOUNT_MANAGER: "Account Manager",
      ROLE_MANAGER: "Manager",
      ROLE_LINE_MANAGER: "Line Manager",
      ROLE_SUPER: "Super",
      ROLE_SHIPMENT_USER: "Shipment User",
      ROLE_CUSTOMER_REPRESENTATIVE: "Customer Representative",
      ROLE_CALENDAR_ADMIN: "Calendar Admin",
      ROLE_IT_USER: "IT Manager",
      ROLE_TOP_MANAGER: "Top Manager",
      ROLE_READ_ONLY: "Read Only",
      ROLE_FINANCE_USER: "Finance Worker",
    },
    secondaryRole: "Secondary Role",
    secondaryRoleMsg:
      "A secondary role allows users to have authority only in a specific area. For example: ELK Warehouse Manager cannot be used alone. Since we have warehouses in 2 different areas, it should be used to separate warehouse users.",
    /** Logistics */
    logistics: {
      title: "Logistics",
      freeZoneOperations: "Free Zone Operations",
      trackNo: "Track NO",
      invoiceNumber: "Invoice Number",
      date: "Date",
      productCode: "Product Code",
      GTIP: "GTIP",
      totalAmount: "Total Amount",
      unit: "Unit",
      totalPrice: "Total Price",
      origin: "Origin",
      shippingCountry: "Shipping Country",
      retrieveInvoices: "Retrieve Invoices",
      compareWithFile: "Compare With File",
      compareWithFileInfo: "Free Zone Work Order File Comparison",
      isValidMessage: "All information is valid.",
      isInValidMessage: "Invalid rows detected.",
      checkTable: "Please check table.",
      palletCount: "Pallet Count",
      weight: "Total Weight",
      startProcess: "Start Process",
      totalMissing: "Total Missing",
      purchaseOrders: "(SB) Purchase Orders",
      purchaseOrderShipments: "Purchase Order Shipments",
      supplier: "Supplier",
      purchaseOrderShipmentsStatus: {
        "0": "INITIAL",
        "1": "WAITING SHIPMENT",
        "2": "SHIPMENT INPROGRESS",
        "3": "SHIPMENT COMPLETED",
        "-1": "SHIPMENT ERROR",
      },
      deletePurchaseOrder: "Delete Purchase Order",
      deletePurchaseOrderWarningMsg:
        "Are you sure you want to delete the purchase?",
      deletePurchaseOrderSuccessMsg: "Purchase order deleted.",
      productInformation: "Product Information",
      totalNumberOfEnteredProducts: "Total Number of Entered Products",
      boxInformation: "Box Informations",
      createPackage: "Create Package",
      productionDate: "Production Date",
      batchNo: "Lot/Batch No",
      packedId: "Packed ID",
      boxCount: "Box Count",
      saveAndGenerateQR: "Save and Generate QR",
      purchaseOrderItemStatus: {
        "0": "WAITING",
        "1": "IN PROGRESS",
        "2": "COMPLETED",
        "-1": "ERROR",
      },
      price: "Price",
    },
    /** Sync */
    sync: {
      customer: {
        warningInfo:
          "{from_customer_pk} customer will be converted to {to_customer_pk} customer.",
        successMsg: "Customer synchronization has been done successfully.",
      },
      product: {
        warningInfo:
          "{from_product_pk} product will be converted to {to_product_pk} product.",
        successMsg: "Product synchronization has been done successfully.",
      },
      projectName: "Project Name",
      activityCategory: "Activity Category",
      quoteHeadline: "Quote Headline",
      oldCustomer: "Old Customer",
      oldProduct: "Eski Product",
      projectsToBeAffected: "Projects To Be Affected",
      activitiesToBeAffected: "Activities To Be Affected",
      quotesToBeAffected: "Quotes To Be Affected",
      sampleToBeAffected: "Samples To Be Affected",

      projectsToBeAffectedForProduct: "This convert affects {count} projects.",
    },
    /** Synchronizations */
    synchronizations: {
      title: "Synchronizations",
      logoSynchronizations: "Logo Synchronizations",
      importTasks: "Import Tasks",
      importTypes: {
        LIST_PRICE: "List Prices",
      },
    },
    /** Dictionary */
    dictionary: {
      title: "Dictionary",
      newWord: "New Word",
      addNewWord: "Add New Word",
      editWord: "Edit Word",
      addWordSuccessMsg: "Word successfully created.",
      editWordSuccessMsg: "Word successfully updated.",
      deleteWordSuccessMsg: "Word successfully deleted.",
      delete: {
        modalTitle: "Delete Word",
        warningMsg: "The word will be delete. Do you approve this action?",
      },
    },
    /** Storage */
    storage: {
      title: "Personal Storage",
      foldersTitle: "Folders",
      uploadFiles: "Upload File(s)",
      allowImageTypes: "jpg/png/jpeg/webp files are allowed.",
      giveADirectoryName: "Directory Name",
      deleteFolder: "Delete Folder",
      searchFolders: "Search Folder",
      noSelectedFolder: "No selected folder.",
      noSelectedFile: "No selected file.",
      chooseAFolder: "Choose a folder.",
      expiresAtMsg: "These files cannot be downloaded after {date}",
      legalNotice: "Legal Notice",
      delete: {
        warningMsg: "Are you sure you want to delete this folder?",
        successMsg: "Deleted folder.",
      },
      deleteFile: {
        title: "Delete File(s)",
        warningMsg: "Are you sure you want to delete this file(s)?",
        successMsg: "Deleted file(s).",
      },
      shareFile: {
        title: "Share File(s)",
        selectedFiles: "Selected File(s)",
      },
      shareFolder: {
        title: "Share Folder",
        selectedFolders: "Selected Folder",
      },
      downloadFile: {
        downloadingFile: "The file(s) is downloading...",
        downloadedFile: "File(s) downloaded.",
        notDownloadFile: "The file(s) could not be downloaded.",
      },
      add: {
        addNewFolder: "Add New Folder",
        successMsg: "New folder created.",
      },
    },
    /** Samples */
    samples: {
      title: "Samples",
      newSample: "New Sample Form",
      addNewSample: "Create New Sample Form",
      noProductErrorMsg:
        "Sample form cannot be created without creating a product!",
      requiredProductErrorMsg:
        "Please fill in the required fields ( * ) in the product table completely and make sure that the product information is correct.",
      thoseInProgress: "Those in Progress",
      boxCount: "Box Number",
      totalBoxCount: "Total Box Count",
      status: {
        WAITING_CUSTOMER_REPRESENTATIVE: "Waiting for Customer Rep",
        WAITING_IMEI_CONTROL: "Waiting for IMEI Information",
        WAITING_WAYBILL_COMPLETE: "Waiting for Waybill Creation",
        WAITING_STORAGE_DUMPSTER: "Waiting for Package Information",
        WAITING_FINANCE_SUPERVISOR: "Waiting Finance Supervisor",
        WAITING_SHIPMENT: "Waiting Shipment",
        WAITING_DIRECT_SHIPMENT: "Waiting Direct Shipment",
        CANCELLED_SAMPLE: "Sample Cancelled",
        CANCELLED_DIRECT_SHIPMENT: "Direct Shipment Cancelled",
        COMPLETED_SHIPMENT: "Shipment Completed",
        COMPLETED_DIRECT_SHIPMENT: "Direct Shipment Completed",
      },
      formType: {
        default: "Customer",
        personal: "Personal",
        direct: "Direct",
      },
      add: {
        direct: {
          title: "Direct",
          text: "This sample form should be used for sending samples that are not in inventory.",
        },
        personal: {
          title: "To Person",
          text: "This sample form should be used if the sample recipient is not a customer.",
        },
        default: {
          title: "To Customer",
          text: "This sample form should be used if the samples are being sent from inventory to our customer.",
        },
        successMsg: "Created new sample form.",
        deliveryInformationWarningMsg:
          "Please fill out the required delivery information completely.",
        hasNoCurrentRecordErrorMsg:
          "The selected customer has no current record.",
        hasNoCurrentRecordErrorMsgInfo:
          "Create a current record for the customer or fill in the 'TR ID Number' and 'Phone Number' information to issue a personal invoice to the contact person.",
        hasNoCurrentRecordWarningMsg:
          "The selected customer has no current record.",
        hasNoCurrentRecordWarningMsgInfo:
          "Using the contact information in the delivery information, issue a delivery note to the Sample Customer.",
      },
      confirm: {
        modalTitle: "Confirm Sample Form",
        warningMsg: "Are you sure you want to approve the sample form?",
        successMsg: "Sample form approved.",
      },
      cancel: {
        modalTitle: "Cancel Sample Form",
        cancellationReason: "Cancellation Reason",
        warningMsg: "Are you sure you want to cancel the sample form?",
        successMsg: "Sample form canceled.",
      },
      edit: {
        successMsg: "Updated sample form.",
        editingSampleForm: "SAMPLE FORM IS BEING EDITED",
      },
      note: {
        add: {
          modalTitle: "Add New Saple Note",
          successMsg: "New sample note created.",
        },
        edit: {
          modalTitle: "Edit Saple Note",
          successMsg: "Sample note updated.",
        },
        delete: {
          modalTitle: "Delete Sample Note",
          warningMsg: "Are you sure you want to delete the sample note?",
          successMsg: "Sample note deleted.",
        },
      },
      sequential: {
        errorMsg:
          "Please fill in the mandatory fields ( * ) in the product table completely and make sure that the container number always starts with the number 1 and that you enter non-missing consecutive digits.",
      },
      product: {
        warningMsg:
          "In the sample, there must be at least 1 product. Otherwise, you can send a delete request to the sample.",
        add: {
          successMsg: "New sample product created.",
        },
        edit: {
          successMsg: "Sample product updated.",
        },
        delete: {
          modalTitle: "Delete Sample Product",
          warningMsg: "Are you sure you want to delete the sample product?",
          successMsg: "Sample product deleted.",
        },
      },
    },
    /** Price Calculator */
    priceCalculator: {
      header: "Price Calculator",
      tabs: {
        marginCalculate: "Calculate Margin",
        purchaseCalculate: "Calculate Purchase Price",
        saleCalculate: "Calculate Sale Price",
      },
      label: {
        margin: "Margin",
        purchasePrice: "Purchase Price",
        salePrice: "Sale Price",
      },
    },
    /** Target */
    targets: {
      title: "Annual Targets",
      target: "Target",
      sales: "Sales",
      addCustomer: "Add Customer",
      addCustomerToTarget: "Hedefe Müşteri Ekle",
      rollingExpTotal: "Rolling Potential",
      companySalesTarget: "Company Sales Target",
      personalSalesTarget: "Filtered Sales Target",
      companyProfitTarget: "Company Profit Target",
      personalProfitTarget: "Filtered Profit Target",
      personalTargetedCustomers: "Filtered Target Customers",
      targetedCustomers: "Targeted Customers",
      totalSales: "Total Sales",
      totalProfit: "Total Profit",
      salesTarget: "Sales Target",
      profitTarget: "Profit Target",
      salesVisitTarget: "Sales Visit Target",
      marketingVisitTarget: "Marketing Visit Target",
      actualSalesVisitTarget: "Actual Sales Visit Target",
      actualMarketingVisitTarget: "Actual Marketing Visit Target",
      pendingApproval: "Pending Approval",
      previousYears: "Previous Years",
      myCustomerOnly: "My Customer Only",
      targetErrorMsg:
        "The customer's monthly and annual targets are not equal.",
      targetSuccessMsg: "The customers's monthly target have been entered.",
      users: {
        userVisitTargets: "User Visit Targets",
        webcallTarget: "Webcall Target",
        visitTarget: "Visit Target",
        add: {
          successMsg: "Operation Successful! New user target created.",
        },
        edit: {
          successMsg: "Operation Successful! User target updated.",
        },
        delete: {
          modalTitle: "Delete User Annual Target",
          warningMsg:
            "Are you sure you want to delete the annual target for the user?",
          successMsg: "Operation Successful! User target deleted.",
        },
      },
      lines: {
        lineAnnualTargets: "Line Annual Targets",
        lineMonthlyTargets: "Line Monthly Targets",
        customerMonthlyTargets: "Customer Monthly Targets",
        values: "Values",
        customerSalesAndProfitTargets: "Customer SALES/PROFIT Targets",
        monthly: {
          salesTargetErrorMsg:
            "Monthly Sales Total Must Be Equal to Annual Sales Total.",
          profitTargetErrorMsg:
            "Monthly Profit Total Must Be Equal to Annual Profit Total.",
          edit: {
            successMsg:
              "Operation Successful! Line Monthly Targets table is updated.",
          },
        },
        add: {
          successMsg: "Operation Successful! New target created.",
        },
        edit: {
          successMsg: "Operation Successful! Target updated.",
        },
        delete: {
          modalTitle: "Delete Line Annual Target",
          warningMsg:
            "Are you sure you want to delete the annual target for the line?",
          successMsg: "Operation Successful! Target deleted.",
        },
      },
    },
    /** Reports */
    reports: {
      title: "Reports",
      reportNotFound: "Report not found.",
      activityReport: "Activity Report",
      marketingPerformanceReport: "Marketing Performance Report",
      legends: {
        red: "Number of activity notes not entered.",
        orange: "Number of activity notes entered late.",
        green: "Number of activity notes entered on time.",
      },
    },
    /** Approvals */
    approvals: {
      title: "Approvals",
      targets: {
        title: "Target Module Approvals",
        tableEmptyText: "There is no approval for the target module.",
        approve: {
          warningMsg: "Are you sure you want to approve this target?",
          selectedWarningMsg: "Are you sure you want to approve the targets?",
          successMsg: "Operation Successful! Target approved.",
        },
        reject: {
          warningMsg: "Are you sure you want to reject this target?",
          selectedWarningMsg: "Are you sure you want to reject the targets?",
          successMsg: "Operation Successful! Target rejected.",
        },
      },
      projects: {
        title: "Project Deletion Approvals",
        tableEmptyText:
          "There is no project deletion request for the project module.",
        approve: {
          warningMsg:
            "Are you sure you want to approve the project deletion request?",
          successMsg:
            "Operation Successful! Project deletion request approved.",
        },
        reject: {
          warningMsg:
            "Are you sure you want to reject the project deletion request?",
          successMsg:
            "Operation Successful! Project deletion request rejected.",
        },
      },
      activities: {
        title: "Activity Deletion Approvals",
        tableEmptyText:
          "There is no activity deletion request for the activity module.",
        approve: {
          warningMsg:
            "Are you sure you want to approve the activity deletion request?",
          successMsg:
            "Operation Successful! Activity deletion request approved.",
        },
        reject: {
          warningMsg:
            "Are you sure you want to reject the activity deletion request?",
          successMsg:
            "Operation Successful! Activity deletion request rejected.",
        },
      },
    },
    /** POF */
    pof: {
      title: "Purchase & Quote Tracking",
      newPOF: "New POF",
      selectedOuoteInfoText: "{count} quotes selected.",
      createButton: "Create POF",
      downloadButton: "Download POF",
      divideIntoUnits: "Divide Into Units",
      productInvalidErrorMsg:
        "Please make sure to fill in the required fields (*) in the 'Purchase' and 'Deliver To Customer' tables and save the changes.",
      status: {
        CANCELLED: "Cancelled",
        COMPLETED: "Completed",
        APPROVED_PURCHASING: "Purchasing Approved",
        APPROVED_CUSTOMER_REPRESENTATIVE: "Customer Representative Approved",
        WAITING_PURCHASING: "Waiting Purchasing",
        WAITING_CUSTOMER_REPRESENTATIVE: "Waiting Customer Representative",
        DRAFT: "Draft",
      },
      logoCreationStatusMsg: {
        customer:
          "Customer information must be entered in order to confirm the order.",
        product:
          "Product information must be entered in order to confirm the order.",
      },
      add: {
        loadingText: "Purchase Form is being created...",
        successMsg: "Purchase Form created.",
      },
      edit: {
        successMsg: "Purchase Form updated.",
        productInvalidErrorMsg:
          "Please make sure to fill in the required fields (*) in the product list and save the changes.",
      },
      confirm: {
        modalTitle: "Confirm Purchase Form",
        warningMsg: "Are you sure you want to approve the Purchase Form?",
        successMsg: "Purchase Form approved.",
      },
      cancel: {
        modalTitle: "Cancel Purchase Form",
        cancellationReason: "Cancellation Reason",
        warningMsg: "Are you sure you want to cancel the Purchase Form?",
        successMsg: "Purchase Form canceled.",
      },
      attachments: {
        add: {
          successMsg: "New file added to Purchase Form.",
        },
        edit: {
          successMsg: "File edited.",
        },
        delete: {
          modalTitle: "Delete File",
          warningMsg:
            "Are you sure you want to delete the file of the Purchase Form?",
          successMsg: "The file has been deleted.",
        },
      },
      product: {
        delete: {
          modalTitle: "Delete Purchase Product",
          warningMsg: "Are you sure you want to delete the purchase product?",
          successMsg: "Purchase product deleted.",
        },
        add: {
          successMsg: "New product created.",
        },
        edit: {
          successMsg: "Product updated.",
        },
        customerQuantities: {
          delete: {
            modalTitle: "Delete Pof Customer Quantity",
            warningMsg:
              "Are you sure you want to delete the pof product customer quantity?",
            successMsg: "Pof Customer Quantity deleted",
          },
          add: {
            successMsg: "",
          },
          edit: {
            successMsg: "",
          },
        },
        supplierQuantities: {
          delete: {
            modalTitle: "Delete Pof Supplier Quantity",
            warningMsg:
              "Are you sure you want to delete the pof product supplier quantity?",
            successMsg: "Pof Supplier Quantity deleted",
          },
          add: {
            successMsg: "",
          },
          edit: {
            successMsg: "",
          },
        },
      },
    },
    /** Inventory */
    inventory: {
      title: "Inventory Information",
      inventory: "Inventory",
      stockInformation: {
        SB_ARIZALI: "SB ARIZALI",
        SB_DEPO: "SB DEPO",
        ELK_DEPO: "ELK DEPO",
        ELK_ARIZALI: "ELK ARIZALI",
        SOLECTRON: "SOLECTRON",
        PENTA: "PENTA",
        NUMUNE: "NUMUNE",
        KONSINYE: "KONSİNYE",
        E_TICARET: "ETİCARET",
        TOTAL: "TOPLAM",
        ALL_WAREHOUSES: "TÜM DEPOLAR",
      },
      productAnalysis: {
        modalTitle: "Product Analysis",
      },
      usePriceWarningMsg:
        "The purchase price you want to use does not match the exchange rate you have selected in your offer. Are you sure you want to continue?",
    },
    /** List Prices*/
    listPrices: {
      title: "List Prices",
      uploadListPrice: "Upload List Price",
      add: {
        successMsg: "List price uploaded.",
      },
    },
    /** Special Prices*/
    specialPrices: {
      title: "Special Prices",
      newSpecialPrice: "New Special Prices",
      quoteType: "Quote Type",
      quoteDate: "Quote Date",
      noProductErrorMsg:
        "Special Price cannot be created without creating the special price product!",
      add: {
        modalTitle: "Create Special Price",
        successMsg: "New special price created.",
      },
      edit: {
        title: "Edit Special Price",
        successMsg: "Special price updated.",
      },
      delete: {
        modalTitle: "Delete Special Price",
        warningMsg: "Are you sure you want to delete Special Price?",
        successMsg: "Special Price has been deleted.",
      },
    },
    /** Quote Tracking */
    quoteTracking: {
      title: "Quote Tracking",
      quoteOrderRate: "Quote to Order Conversion Rate",
      quoteNotConvertedToOrders:"Quotes Not Converted to Orders",
      weekly: "Weekly",
      monthly: "Monthly",
      quarterly: "Quarterly",
      yearly: "Yearly",
      overTheLastWeek: "Over the last week",
      overTheLastMonth: "Over the last month",
      overTheLastThreeMonth: "Over the last 3 months",
      yearToDate: "Year to Date",
      allSalesQuotes: "All Sales/Quotes",
      salesTeam: "Sales Team",
      thirtyDays:"30 Days",
      thirtyToFortyFiveDays:"30-45 Days",
      overFortyFiveDays:"+45 Days",
    },
    /** Tags */
    tags: {
      title: "Tags",
      warningToCreateMsg: "Please register the new tag.",
      add: {
        successMsg: "New tag created.",
      },
    },
    /** Devices */
    devices: {
      title: "Devices",
      roomTemperature: "Room Temperature",
      serverTemperature: "Server Temperature",
      humidity: "Humidity",
    },
    /** Announcement */
    announcement: {
      title: "Announcement",
      deadline: "Deadline",
      edit: "Announcement Is Being Edited...",
      create: "Create Announcement",
      successMsg: "Announcement successfully updated.",
    },
    /**Permission Form */
    permissionForm: {
      title: "Form",
      deadline: "Deadline",
      create: "Create Form",
      successMsg: "Form successfully created.",
      chooseForm: "Choose Form",
      pleaseChooseForm: "Please select the form you want to create.",
      fillForm: "Fill Form",
      leaverequestForm: "Leave Request Form",
      annualLeaveForm: "Annual Leave Form",
      excuseLeaveForm: "Excuse Leave Form",
    },
    /** Language */
    languages: {
      title: "Language",
      turkish: "Turkish",
      english: "English",
    },
    /** Buttons */
    buttons: {
      filter: "Filter",
      resetFilter: "Reset Filter",
      submit: "Submit",
      approve: "Approve",
      reject: "Reject",
      selectedApprove: "Selected Approve",
      selectedReject: "Selected Reject",
      close: "Close",
      delete: "Delete",
      deleteDevice: "Delete Device",
      download: "Download",
      downloadAll: "Download All",
      downloadReports: "Download Reports",
      addReports: "Add Report",
      uploadMedia: "Upload Media",
      share: "Share",
      save: "Save",
      saveAll: "Save All",
      history: "History",
      saveAndClose: "Save and Close",
      remove: "Remove",
      follow: "Follow",
      unfollow: "Unfollow",
      okay: "OK",
      repeat: "Repeat",
      update: "Update",
      cancel: "Cancel",
      yes: "Yes",
      no: "No",
      upload: "Upload",
      uploadPhoto: "Upload Photo",
      selectPhoto: "Select Photo",
      crop: "Crop",
      preview: "Preview",
      send: "Send",
      choose: "Choose",
      change: "Change",
      selectAll: "Select All",
      clear: "Clear",
      tryAgain: "Try Again",
      start: "Start",
      launch: "Launch",
      continue: "Continue",
      edit: "Edit",
      copy: "Copy",
      copied: "Copied",
      yesIAccept: "Yes, I accept",
      yesIApprove: "Yes, I approve",
      yesIReject: "Yes, I reject",
      enable2fa: "Enable 2FA",
      create: "Create",
      addNote: "Add Note",
      addPhoto: "Add Photo",
      reset: "Reset",
      merge: "Merge",
      addTask: "Add Task",
      detail: "Detail",
      add: "Add",
      switchUser: "Switch User",
      getBack: "Get Back",
      addAddress: "Add Address",
      confirmTransaction: "Confirm Transaction",
      back: "Back",
      expoReport: "Expo Report",
      expoMedia: "Expo Gallery",
      downloadReport: "Download Report",
      iReadIApprove: "I read, I approve",
      confirmAndExit: "Confirm and Exit",
      exit: "Exit",
      deleteImage: "Delete Image",
      changeImage: "Change Image",
      complete: "Complete",
      previous: "Previous",
      next: "Next",
      seeMore: "See More",
    },
    /** Phone Number Input*/
    phoneNumberInput: {
      countrySelector: {
        placeholder: "Country code",
        error: "Choose country",
      },
      phoneInput: {
        placeholder: "Phone number",
        example: "Example:",
      },
    },
    /** Badge */
    badge: {
      active: "Active",
      disconnected: "Disconnected",
      done: "Done",
      passive: "Passive",
      enabled: "Enabled",
      disabled: "Disabled",
      pending: "Pending",
      successful: "Successful",
      completed: "Completed",
      notCompleted: "Not Completed",
      waitingApproval: "Waiting Approval",
      approved: "Approved",
      notApproved: "Not Approved",
    },
    /** Tooltip */
    tooltip: {
      visibleActivity: "Visible Activity",
      userUpdateInfo: "Please use this filter together with updater filter.",
      edit: "Edit",
      editWord: "Edit Word",
      deactive: "Deactive User",
      changepassword: "Change User Password",
      detail: "Detail",
      delete: "Delete",
      remove: "Remove",
      active: "Activate User",
      quantity: "Quantity Used On a Card",
      productionPrice: "Estimated Selling Price",
      currency: "Currency",
      rollingPrice: "Rolling Sales Forecast Ratio",
      moqMrq: "Total Potential",
      max12Char: "Maximum 12 Characters",
      deliveryTypeMax16Char:
        "Yellow Warning: Line is truncated after 16 characters for correct PDF display",
      newCustomerContact: "Create New Customer Contact",
      newContact: "Create New Contact",
      addRow: "Add Row",
      approvedAndPendingTarget: "Approved Target / Pending Target",
      RemoveRow: "Remove Row",
      newProduct: "Create New Product",
      clean: "Clear",
      newCustomer: "Create New Customer",
      criticalImportance: "Critical Importance",
      highImportance: "High Importance",
      mediumImportance: "Medium Importance",
      lowImportance: "Low Importance",
      noImportance: "No Importance",
      editNote: "Edit Note",
      deleteNote: "Delete Note",
      activity: {
        importanceOptions: {
          firstStar:
            "1 ☆ = All Empa Line team and the Customer's Sales Representative will be informed.",
          secondStar:
            "2 ☆ = All Empa Line team, the Customer's Sales Representative and all managers will be informed.",
          thirdStar:
            "3 ☆ = All Empa Line team, the Customer's Sales Representative, all managers and the entire Sales Team will be informed.",
        },
      },
      showQuote: "Show Quote",
      showProducts: "Show Products",
      divideIntoParts: "Divide into Parts",
      projectHistory:
        "Recorded changes; <ul><li>Project Creator</li><li>Project Name</li><li>Expected Mass Production Date of the Project</li><li>Product Addition</li><li>Phase of Product</li><li>Register Availability of the Product</li><li>Product Sample Status</li><li>Product Deletion Status</li><li>Project Deletion Status</li></ul>",
      quoteHistory:
        "Recorded changes; <ul><li>Quote Title</li><li>Is Budgetary?</li><li>Delivery Type</li><li>Quote Term</li><li>Payment Type</li><li>Product Code</li><li>Product Quantity</li><li>Buy - Sell Price of the Product</li></ul>",
    },
    /** Filters */
    filters: {
      savedFilter: "Saved Filters",
      noSavedFilter: "No Saved Filter",
      selectFilter: "Select Filter",
      saveFilter: "Save Filter",
      updateFilter: "Update Filter",
      filterName: "Filter Name",
      successMessage: "Filter successfully created.",
      updateMessage: "Filter successfully updated.",
      deleteMessage: "Filter successfully deleted.",
    },
    /** Chat */
    chatDrawer: {
      chatRooms: "Chat Rooms",
      disconnected: "Disconnected",
      online: "Online",
      typeMessage: "Type a message...",
    },
    /** Changelog */
    changelog: {
      title: "Changelog",
    },
    /** Forms */
    form: {
      message: {
        required: "This value is required.",
        turkishCharacters: "Please use Turkish characters.",
        capsLock: "Caps Lock is on. Please turn it off.",
        email: "Please enter valid email.",
        idNumber: "Please enter a valid identity number.",
        minLength:
          "This value is too short. It should have {length} characters or more.",
        maxLength:
          "This value is too long. It should have {length} characters or fewer.",
        alphaAndAlphaNum: "You can only use letters, numbers.",
        select: "Please select at least 1 option.",
        fileLength: "Please upload at least 1 file.",
        alphaNum:
          "Please enter numbers and letters only. Do not use Turkish characters.",
        passwordMustMatch: "Passwords must match.",
        enter2FAcode: "Please enter your 6 digit 2FA code",
        specialUpperandLowerCaseNumberChar:
          "Your password must contain at least one uppercase letter, one lowercase letter, one number and '{'@'}{'$'}{'%'}{'!'}' It must contain special characters such as.",
        emailDomain: "Email address can only be empa.com",
        phoneNumberFormat:
          "Please make sure you enter the correct phone number.",
        deleteNote: "Note successfully deleted.",
        addWordSuccess: "Word successfully created.",
        editWordSuccess: "Word successfully updated.",
        deleteWordSuccess: "Word successfully deleted.",
        emptyFields: "Please fill in the fields below.",
        emptyProducts: "Please add at least one product.",
        valuesRequired: "This values are required.",
        isPhaseLost:
          "When the stage is entered as LOST, the note field is required.",
        min: "This field must be at least {value} characters.",
        max: "This field must contain at most {value} characters.",
        issueRequest: "The issue/request was successfully created.",
        passwordRegex:
          "This field must contain at least one number and one letter.",
        url: "Please make sure that you start the link with 'https://' and enter the correct link.",
        tableRow: "Please add at least 1 row to the table.",
      },
    },
    /** Table */
    table: {
      tableEmptyText: "There are no records to show.",
      emptyFilteredText: "There are no records matching your request.",
    },
    /** Calendar */
    calendar: {
      title: "Calendar",
      events: "Events",
      calendarOfYearTitle: "{year} Calendar of Events/Organizations",
      thisMonthEventOrOrganization: "Events in {month}",
      newEventOrOrganization: "New Event/Organization",
      hasMiniCard: "Is there a mini card?",
      cardImage: "Card Image",
      add: {
        modalTitle: "Create New Event/Organization",
        successMsg: "New event/organization created.",
      },
      edit: {
        modalTitle: "Edit Event/Organization",
        successMsg: "Event/Organization updated.",
      },
    },
    /** 404 page */
    error404info: {
      pagenotfound: "Page Not Found 🕵🏻‍♀️",
      backplatform: "Back To Platform",
    },
    /** Maintenance Page */
    maintenance: {
      title: "Site Bakımda 🛠",
      text: "Lütfen daha sonra tekrar kontrol edin.",
    },
    datePicker: {
      yearly: "Yıllık",
      monthly: "Aylık",
      weekly: "Haftalık",
      daily: "Günlük",
      hourly: "Saatlik",
      minutely: "Dakikalık",
      secondly: "Saniyelik",
    },
    errorCodes: {
      errorMsg: "There was an error. Please try again.",
      "500": "Internal server error. Please contact the technical team.",
      "404": "Request failed.",
      "400":
        "Select a valid choice. That choice is not one of the available choices.",
      "409": "Requested resource exists",
      "40102": "This user does not exist.",
      "40104": "Please check your password.",
      "400103":
        "Your 2FA device installation has expired, and you cannot log in. Please contact your administrator to resolve the issue.",
      user_inactive:
        "Your account has been closed. If you think this is an error, please consult your administrator",
      ERR_BAD_REQUEST: "Request failed.",
      firefoxBrowserErroeMsg:
        "This request is currently not supported by the Firefox browser. Please change your browser and try.",
    },
    swal: {
      error: {
        error: "Error",
        title: "There was an error.",
        buttonText: "Please try again!",
        signInTitle: "Incorrect email and/or password.",
        headlineRequired: "Headline is required.",
        noteContentLength: "Note content must be at least 140 characters.",
        taskDescriptionRequired:
          "When assigning tasks, the explanation field cannot be left blank as FYA has been selected.",
        okay: "Okay!",
        permissionDenied:
          "You dont have permission to do this action. Please contact with your manager",
      },
      success: {
        success: "Success",
        title: "Operation Successful!",
        copy: "Copied!",
        likeMessage: "You have updated the liked information.",
      },
      warning: {
        warning: "Warning",
        okay: "Okay!",
      },
      favorites: {
        updatedFavorites: "Your Favorites list has been updated.",
      },
    },
    upload: {
      componentText: "Drop file here or {button}",
      buttonText: "click to upload",
    },
    ticket: {
      title: "Ticket successfully created.",
      resolved: "Resolved",
      nonResolved: "Not Resolved",
      waitingUserResponse: "Waiting For User Response",
      tableEmptyText: "You have no ticket.",
    },
    greetings: {
      morning: "Good morning",
      afternoon: "Good afternoon",
      evening: "Good evening",
    },

    filterTitle: {
      searchModule: "What are you looking for?",
    },

    expense: {
      newExpense: "Create Expense Form",
      title: "Expense Form",
      expenseVouchers: "Expense Vouchers",
      status: {
        DRAFT: "Draft",
        WAITING_MANAGER_APPROVAL: "Waiting Manager Approval",
        MANAGER_APPROVED: "Manager Approved",
        MANAGER_REJECTED: "Manager Rejected",
        WAITING_FINANCE_CONTROL: "Waiting Finance Control",
        FINANCE_APPROVED: "Finance Approved",
        FINANCE_REJECTED: "Finance Rejected",
        COMPLETED: "Completed",
      },
      add: {
        title: "Create Expense Form",
        successMsg: "Expense Form created.",
      },
      edit: {
        title: "Edit Expense Form",
        successMsg: "Expense Form updated.",
      },
      delete: {
        modalTitle: "Delete Expense Form",
        warningMsg: "Are you sure you want to delete the expense form?",
        successMsg: "Your expense form deletion request has been received.",
      },
      confirm: {
        modalTitle: "Confirm Expense Form",
        warningMsg: "Are you sure you want to approve the expense form?",
        successMsg: "Expense form approved.",
      },
      cancel: {
        modalTitle: "Cancel Expense Form",
        cancellationReason: "Cancellation Reason",
        warningMsg: "Are you sure you want to cancel the expense form?",
        successMsg: "Expense form canceled.",
      },
      expenseVoucher: {
        add: {
          successMsg: "Expense Voucher created.",
        },
        edit: {
          successMsg: "Expense Voucher updated.",
        },
        delete: {
          modalTitle: "Delete Expense Voucher",
          warningMsg: "Are you sure you want to delete the expense voucher?",
          successMsg: "Expense Voucher deleted.",
        },
      },
    },
    kpiManagement: {
      title: "KPI Management Panel",
    },
    kpiMarketingManagement: {
      title: "KPI Marketing Management",
      lineTargets: {
        title: "Line Targets",
        tableHeader: {
          line: "Line",
          newSocketCount: "New Socket Count",
          newSocketAmount: "New Socket Amount (kUSD)",
          newCustomerCount: "New Customer Count",
        },
        success: {
          title: "Success",
          message: "The data you entered has been edited successfully.",
        },
      },
      userTargets: {
        title: "User Targets",
      },
    },
    logoCustomers: {
      title: "Logo Customers",
      currencyMessage:
        "Please select the exchange rate accounts for the current account you want to create.",
      status: {
        IN_PROGRESS: "In Progress",
        WAITING_FOR_APPROVAL: "Waiting For Approval",
        REVISION_REQUIRED: "Revision Required",
        APPROVED: "Approved",
        DRAFT: "DRAFT",
        UPDATE_REQUESTED: "Update Requested",
        CANCELLED: "Cancelled",
      },
      edit: {
        title: "New Customer Form",
        contactInfo: "Contact Information",
        commercialInfo: "Commercial Information",
        successMsg: "The customer successfully saved on logo.",
        fileErrorMsg: "Please enter your tax certificate file first.",
        successSaveMsg: "The customer successfully saved",
      },
      rejectModal: {
        title: "Request Update Reason",
        reason: "Please write the reason for request",
      },
    },
    logoProducts: {
      title: "Logo Products",
      status: {
        IN_PROGRESS: "In Progress",
        WAITING_FOR_APPROVAL: "Waiting For Approval",
        REVISION_REQUIRED: "Revision Required",
        APPROVED: "Approved",
        DRAFT: "DRAFT",
        UPDATE_REQUESTED: "Update Requested",
        CANCELLED: "Cancelled",
      },
      edit: {
        title: "New Logo Product Form",
        kdv: {
          kdvRatio: "KDV Ratio",
          purchasing: "Purchasing",
          sales: "Sales",
          retail: "Retail",
          rebate: "Rebate",
          retailRebate: "Retail Rebate",
          kdv20: "Apply 20% to All",
        },
        gtipCode: "GTIP Code",
        createGtip: {
          title: "Create New GTIP",
          customsDuty: "Customs Duty",
          additionalCustomsDuty: "Additional Customs Duty",
          additionalFinancialObligation: "Additional Financial Obligation",
          additionalFinancialObligationUsa:
            "Additional Financial Obligation Usa",
          kkdf: "KKDF",
          hasTarexControl: "Has Tarex Control",
          hasImeiControl: "Has IMEI Control",
        },
        successMsg: "The product successfully saved on logo.",
        successSaveMsg: "The product successfully saved.",
      },
      rejectModal: {
        title: "Request Update Product",
        reason: "Please write the reason for request",
      },
    },
    tasks: {
      title: "Tasks",
      myTasks: "My Tasks",
      assignedByMe: "Assigned by Me",
      assignee: "Assignee",
      viewProject: "View Project",
      viewActivity: "View Activity",
      viewQuote: "View Quote",
      modules: {
        activity: "Activity",
        quote: "Quote",
        project: "Project",
      },
      complete: {
        modalTitle: "Complete Task",
        successMsg: "Task completed.",
      },
      edit: {
        modalTitle: "Edit Task",
        successMsg: "Task updated.",
      },
      delete: {
        modalTitle: "Delete Task",
        warningMsg: "Are you sure you want to delete the task?",
        successMsg: "Task deleted.",
      },
    },
  },

  tr: {
    profile: "Profil",
    myProfile: "Profilim",
    dashboard: "Ana Sayfa",
    documents: "Belgeler",
    documentation: "Dokümantasyon",
    administration: "Yönetim",
    activity: "Aktivite",
    notification: "Bildirimler",
    logout: "Çıkış Yap",
    chat: "Sohbet",
    example: "Örnek",
    exampleLink: "Örnek Bağlantı",
    megaMenu: "Mega Menü",
    settings: "Ayarlar",
    day: "Gün",
    today: "Bugün",
    yesterday: "Dün",
    email: "E-posta",
    password: "Şifre",
    newPassword: "Yeni Şifre",
    confirmNewPassword: "Yeni Şifreyi Onayla",
    name: "Adı",
    firstName: "Adı",
    lastName: "Soyadı",
    role: "Rol",
    visibility: "Görüntülenebilirlik",
    visibilityPermissions: "Görüntüleme Yetkileri",
    availablePermissions: "Mevcut Yetkiler",
    selectedPermissions: "Seçilen Yetkiler",
    date: "Tarih",
    title: "Başlık",
    birthDate: "Doğum Tarihi",
    startDate: "Başlangıç Tarihi",
    startDateShortForm: "Baş. Tarihi",
    endDate: "Bitiş Tarihi",
    shortCode: "Kısa Kod",
    twofacode: "2FA Kodu",
    phone: "Telefon Numarası",
    gsmPhone: "Cep Telefonu",
    officePhone: "Ofis Telefonu",
    extensionNumber: "Dahili Numarası",
    location: "Lokasyon",
    username: "Kullanıcı Adı",
    fullName: "Adı Soyadı",
    all: "Tümü",
    pleaseWait: "Lütfen bekleyin...",
    messageOfTheDay: "Günün Mesajı",
    favoriteMenus: "Favori Menüler",
    attachments: "Ekler",
    comments: "Yorumlar",
    positionEnglish: "Pozisyonu (İngilizce)",
    theme: "Tema",
    themeLight: "Açık",
    themeDark: "Koyu",
    themeSystem: "Sistem",
    availableRoles: "Mevcut Roller",
    selectedRoles: "Seçilen Roller",
    availableCustomers: "Mevcut Müşteriler",
    selectedCustomers: "Seçilen Müşteriler",
    orderToQuoteRates: "Sipariş / Teklif Oranları",
    address: "Adresler",
    selectAddress: "Adres Seçiniz",
    addressHeading: "Adres Başlığı",
    activityNumber: "Aktivite Sayısı",
    nameAndLastName: "Ad Soyad",
    currentPassword: "Mevcut Şifre",
    newPassword2: "Yeni Şifre Tekrar",
    updatePassword: "Şifre Güncelleme",
    userInfo: "Kullanıcı Bilgileri",
    two2Until: "2FA Geçerlilik Süresi",
    is2FAEnabled: "2FA Etkin mi?",
    projectName: "Proje Adı",
    projectContact: "Müşteri Kontağı",
    projectImportance: "Önem",
    projectSector: "Uygulama Türü",
    projectSubSector: "Uygulama Alt Türü",
    projectProductionDate: "Tahmini Seri Üretim Tarihi",
    projectEau: "Yıllık Adet",
    projectElt: "Tahmini Proje Süresi",
    companyName: "Firma Adı",
    annual: "Yıllık",
    accountManager: "Satışcı",
    customerRepresentative: "Müşteri Temsilcisi",
    customerSpecialCode: "Müşteri Özel Kod",
    customerGroup: "Müşteri Grubu",
    showSimilarityResults: "Benzerlik Sonuçlarını Göster",
    similarityResults: "Benzerlik Sonuçları",
    similarity: "Benzerlik",
    backlogInfo: "Mevcut Yıla Girecek Olan / Toplam",
    quoteCreator: "Teklif Oluşturan",
    currentTitlecurrentTitle: "Cari Ünvan",
    representative: "Yetkilisi",
    authorizedEmail: "Yetkili Email",
    authorizedTitle: "Yetkili Ünvanı",
    salesRepresentative: "Satış Temsilcisi",
    gender: "Cinsiyet",
    description: "Açıklama",
    additionalInformation: "Ek Bilgi",
    fileAdd: "Dosya Ekle",
    files: "Dosyalar",
    productCode: "Ürün Kodu",
    productDescription: "Ürün Açıklaması",
    materialGroupCode: "Malzeme Grup Kodu",
    customerName: "Müşteri Adı",
    customerLastName: "Müşteri Soyadı",
    customerTitle: "Ünvan",
    customerTitle2: "Müşteri Ünvanı",
    customerCommercialTitle: "Müşteri Ticari Ünvanı",
    main: "Asıl",
    word: "Kelime",
    definition: "Açıklama",
    alternative: "Alternatif",
    line: "Üretici",
    availableLines: "Mevcut Üreticiler",
    selectedLines: "Seçilen Üreticiler",
    user: "Kullanıcı",
    unit: "Birim",
    productsName: "Ürün Adı",
    totalYearlyPotential: "Toplam Yıllık Potansiyel",
    myReports: "Raporlarım",
    lastUpdateDate: "Son Güncelleme Tarihi",
    rollingTotal: "Önümüzdeki 1 Yıl İçin Beklenti",
    totalLifeTime: "Proje Ömür Potansiyeli",
    noFiles: "Dosya Bulunamadı",
    noSharedFoldersOrFiles: "Paylaşılan Klasör veya Dosya Bulunamadı",
    noFilesThisFolder: "Bu klasörde dosya bulunamadı",
    editNotes: "Notu Düzenliyorsunuz",
    savedNotes: "Kaydedilen Notlar",
    pickADay: "Bir gün seç",
    module: "Modül",
    selectModule: "Modül Seçiniz",
    activityType: "Aktivite Türü",
    reference: "Referans",
    referenceNumber: "Referans Numarası",
    externalCode: "Harici Kod",
    note: "Not",
    headline: "Başlık",
    subject: "İçerik",
    subSubject: "Alt İçerik",
    taskAssignee: "Görev Ataması",
    customer: "Müşteri",
    deliveryCustomer: "Teslimat Müşterisi",
    customerContact: "Müşteri Kontağı",
    contactInformation: "Kontak Bilgisi",
    participants: "Katılımcılar",
    category: "Kategori",
    importance: "Önem",
    discussedProjects: "Görüşülen Projeler",
    relatedProjects: "İlişkili Projeler",
    projectsRelatives: "Proje Bağı",
    dateandTime: "Tarih ve Saat Seçiniz",
    selectDate: "Tarih Seçiniz",
    selectTime: "Saat Seçiniz",
    delivery: "Teslimat",
    incoterms: "Teslimat Türü",
    budgetary: "Bütçesel Mi?",
    downloading: "İndiriliyor...",
    selectYear: "Yıl Seçiniz",
    year: "Yıl",
    project: "Proje",
    totalAnnualPotential: "Toplam Yıllık Potansiyel",
    totalPotential: "Toplam Potansiyel",
    rollingPotential: "Rolling Potansiyel",
    rollingExpectation: "Rolling Beklenti",
    projectStatus: "Proje Durumu",
    projectStage: "Proje Aşaması",
    projectPhase: "Proje Aşaması",
    productPhase: "Ürün Aşaması",
    phaseUpdatedDate: "Aşama Güncellenme Tarihi",
    createdDate: "Oluşturulma Tarihi",
    logoCreatedDate: "Oluşturulma Tarihi (Logo)",
    createdBy: "Oluşturan",
    type: "Tür",
    formType: "Form Türü",
    updatedDate: "Güncellenme Tarihi",
    userUpdateDate: "Kullanıcı Güncelleme Tarihi",
    is_register: "Registered?",
    is_secret: "Gizli Mi",
    is_sample: "Sampled?",
    updaters: "Güncelleyenler",
    update: "Güncelleme",
    searchProductCode: "Ürün Kodu Ara...",
    searchProduct: "Ürün Kodu Ara",
    searchProjectNameAndProductCode: "Proje İsmİ Ara...",
    manager: "Yönetici",
    normal: "Normal",
    contacts: "Kontaklar",
    contact: "Kontak",
    sector: "Uygulama Türü",
    selectFile: "Dosya Seç",
    preloadedFiles: "Önceden Yüklenmiş Dosyalar",
    dropFile: "Dosyayı buraya bırakın veya yüklemek için tıklayın",
    selectUser: "Kullanıcı Seçiniz",
    search: "Ara...",
    searchTag: "Etiket Ara...",
    select: "Seç",
    selectSearchType: "Arama Türünü Seç",
    tag: "Etiket",
    creatingTag: "Etiket Oluşturuluyor...",
    alreadyExistTag: "Bu etiket zaten mevcut.",
    typeAndPressEnter: "Yazın ve enter'a basın.",
    typeAndPressEnterInfo:
      "Yeni etiket oluşturmak için metni girin ve ENTER tuşuna basın.",
    you: "Siz",
    status: "Durum",
    twoFAStatus: "2FA Durumu",
    selectStatus: "Durum Seç",
    customize: "Özelleştir",
    product: "Ürün",
    pickADate: "Bir Tarih Seçin",
    minOrderValue: "Min. Sipariş Değeri",
    minOrderPrice: "Minimum Order Fiyatı",
    paymentTerm: "Ödeme Vadesi",
    paymentType: "Ödeme Türü",
    paymentPlan: "Ödeme Planı",
    invoicingType: "Faturalama",
    shipmentType: "Sevkiyat Tipi",
    biba: "Biba",
    backlog: "Backlog",
    sidebarNew: "Yeni",
    goal: "Hedef",
    boxCount: "Kutu Sayısı",
    salesAmount: "Satış Miktarı",
    amount: "Miktar",
    quarterForecast: "Çeyrek Tahmini",
    postCode: "Posta Kodu",
    taxNumber: "Vergi Numarası",
    taxOffice: "Vergi Dairesi",
    lineContact: "Üretici İletişimleri",
    prev: "Önceki",
    next: "Sonraki",
    loading: "Yükleniyor...",
    loadingNoDot: "Yükleniyor",
    loadMore: "Daha Fazla Yükle",
    loadLess: "Daha Az Yükle",
    selected: "{count} Tane Seçili",
    happyBirthday: "Mutlu Yıllar",
    listWiew: "Liste",
    gridView: "Izgara",
    yes: "Evet",
    no: "Hayır",
    lineName: "Üretici Adı",
    validationMin: "Minimum {min} karakter olmalıdır.",
    validationMax: "Maksimum {max} karakter olmalıdır.",
    quantity: "Adet",
    qty: "Adet",
    currency: "Kur",
    price: "Fiyat",
    buyPrice: "Alış Fiyatı",
    salesPrice: "Satış Fiyatı",
    listPrice: "Liste Fiyatı",
    sales: "Satış",
    marketing: "Pazarlama",
    lastNinetyDays: "Son 90 Gün",
    lastOneYear: "Son 1 Yıl",
    reelQuote: "Reel",
    budgetaryQuote: "Bütçesel",
    reealQuoteTotal: "Reel Teklif Toplamı",
    orderAndRealQuoteRatio: "Sipariş / Reel Teklif Oranı",
    orderOrQuote: "Sipariş / Teklif",
    realQuote90Days: "Reel Teklif (90 Gün)",
    orderTotal: "Sipariş Toplamı",
    projectSummary: "Proje Özeti",
    newProjectAndSocketCount: "Yeni Proje / Socket Sayısı",
    additionalIncomeTax: "Ek Gelir Vergisi",
    taxes: "Vergiler",
    incomeTax: "Gelir Vergisi",
    selectProduct: "Ürün Seçiniz",
    selectedProducts: "Seçili Ürünler",
    selectLine: "Üretici Seçiniz",
    searchLine: "Üretici Ara",
    searchUser: "Kullanıcı Ara",
    expirationDate: "Geçerlilik Süresi",
    pinCode: "Pin Kodu",
    setPinCode: "Pin Kodu Belirleyin veya",
    generatePinCode: "Otomatik Pin Kodu Üretin",
    pinCodeInfo: "Lütfen 4 karakterli pin oluşturunuz",
    pleaseEnterPinCode: "Lütfen pin kodu giriniz",
    viewAll: "Tümünü Gör",
    fileName: "Dosya İsmi",
    fileSize: "Dosya Boyutu",
    addToContact: "Rehbere Ekle",
    cities: "Şehirler",
    share: "Paylaş",
    creationDate: "Oluşturulma Tarihi",
    last90DaysActivities: "Son 90 Gün Aktiviteleri",
    countOfVisitMonthly: "Aylık Ziyaret Sayısı",
    countOfVisitMonthlyTarget: "Aylık Ziyaret Hedefi",
    week: "{n}. Hafta",
    isResolved: "Çözüldü Mü?",
    isUserActionNeeded: "Aksiyon Gerekli?",
    createTicket: "Talep Oluştur",
    ticketInfo: "Sorun Bildirin Veya İstek Oluşturun",
    ticketDetail: "Sorun/İstek Detayı",
    responseBy: "Cevaplayan",
    resolvedBy: "Çözen",
    ticketDescription: "Açıklama",
    // Quote
    quoteNo: "Teklif No",
    quoteType: "Teklif Türü",
    quoteLanguage: "Teklif Dili",
    quoteSubjectTitle: "Teklif Konu Başlığı",
    quoteCurrency: "Teklif Kuru",
    customerRefCode: "Müşteri Ref. Code",
    deliveryType: "Teslimat Türü",
    deliveryTime: "Teslimat Süresi",
    deliveryLocation: "Teslimat Yeri",
    isBudgetary: "Bütçesel Mi?",
    quoteMessageContent: "Teklif Mesaj İçeriği",
    standard: "Standart",
    special: "Özel",
    advance: "Avans",
    otherTerms: "Diğer Koşullar",
    weekly: "Haftalık",
    monthly: "Aylık",
    quarter: "Çeyrek",
    quarterly: "Çeyrek",
    margin: "Marj",
    totalPrice: "Toplam Fiyat",
    totalPrice2: "Toplam Maliyet",
    commonPurchaseOffer: "Toplu Satınalım Teklifi ",
    attachedToProject: "Projeye Bağlı Değil",
    unregisteredProject: "Proje Kayıtlı Değil",
    stock: "Stok",
    actualStock: "Gerçek Stok",
    stockInformation: "Stok Bilgisi",
    inventoryInformation: "Envanter Bilgisi",
    formNo: "Form Numarası",
    searchFormNo: "Form Numarası Ara",
    expoName: "Fuar Adı",
    conclusion: "Sonuç",
    media: "Medya",
    countryAndCity: "Ülke/Şehir",
    website: "Web Sitesi",
    backPageWarningMsg:
      "Değişiklikleriniz kaydedilmeyecektir. Devam etmek istiyor musunuz?",
    supplier: "Tedarikçi",
    totalSalesTarget: "Toplam Satış Hedefi",
    totalProfitTarget: "Toplam Kar Hedefi",
    monthlySalesTarget: "Aylık Satış Hedefi",
    monthlyProfitTarget: "Aylık Kar Hedefi",
    lineSalesTarget: "Üretici Satış Hedefi",
    lineProfitTarget: "Üretici Kar Hedefi",
    profit: "Kar",
    lineCode: "Üretici Kodu",
    producerCode: "Üretici Kodu",
    customerCode: "Müşteri Kodu",
    planDate: "Plan Tarihi",
    startTime: "Başlangıç Saati",
    endTime: "Bitiş Saati",
    department: "Departman",
    position: "Pozisyonu",
    selectDepartment: "Departman Seçiniz",
    lastVisitDate: "Son Ziyaret Tarihi",
    target: "Hedef",
    businessCard: "Kartvizit",
    turkishIdentityNumber: "TC Kimlik Numarası",
    deliveryInformation: "Teslimat Bilgileri",
    reasonForDeletion:
      "Eğer silmek istiyorsanız, lütfen silme sebebinizi aşağıya giriniz",
    reasonForDeletionColumn: "Silme Sebebi",
    documentNr: "Doküman No",
    effectiveDate: "Yürürlük Tarihi",
    revisionNr: "Revizyon No",
    revisionDate: "Revizyon Tarihi",
    pageNr: "Sayfa No",
    red: "Kırmızı",
    orange: "Turuncu",
    green: "Yeşil",
    total: "Toplam",
    privateActivity: "Özel Aktivite",
    saveAsImage: "Resim Olarak Kaydet",
    partNumber: "Ürün Kodu",
    internalNumber: "Üretici Kodu",
    customerPartNumber: "Müşteri Ürün Kodu",
    purchaseOrder: "Satın Alma Emri",
    purchaseOrderDontNeedMessage:
      "Üreticiye sipariş açılmayacaktır.  Ürün stoktan gönderilecektir.",
    deliverToCustomer: "Müşteriye Teslimat",
    processDate: "İşlem Tarihi",
    expenseFormNumber: "Masraf Formu Numarası",
    typeOfExpense: "Harcama Türü",
    cost: "Tutar",
    image: "Görsel",
    expotag: "Fuar Etiketi",
    poNumber: "PO Numarası",
    ytdSales: "YTD Satış",
    ytdBooking: "YTD Sipariş",
    ytdOrder: "YTD Sipariş",
    searchAndSelectProduct: "Ürün Ara ve Seç",
    elkStock: "ELK Stok",
    sbStock: "SB Stok",
    totalStock: "Toplam Stok",
    elkAverageUnitPrice: "ELK Ort. Birim Maliyet",
    sbAverageUnitPrice: "SB Ort. Birim Maliyet",
    avgUnitPrice: "Ort. Birim Maliyet",
    priceLists: "Fiyat Listeleri",
    analysis: "Analiz",
    averageUnitPrice: "Ortalama Birim Fiyatı",
    totalAveragePrice: "Toplam Ortalama Fiyat",
    subcontractor: "Alt Yüklenici",
    expiryDate: "Son Geçerlilik Tarihi",
    quoteDate: "Teklif Tarihi",
    isAvailable: "Kullanılabilir",
    unitPrice: "Birim Fiyatı",
    moq: "MOQ",
    mrq: "MRQ",
    milspecCode: "Milspec Code",
    requiredProductErrorMsg:
      "Lütfen ürün tablosunda ki zorunlu tutulan alanları( * ) eksiksiz bir şekilde doldurunuz ve ürün bilgilerin doğruluğundan emin olunuz.",
    species: "Tür",
    lastWorkingHour: "Son Çalışma Saati",
    nextWorkingHour: "Bir Sonraki Çalışma Saati",
    orderNumber: "Sipariş Numarası",
    orderInfo: "Açık Siparişler",
    requestDate: "İstek Tarihi",
    guessDeliveryDate: "Tahmini Teslimat Tarihi",
    priceAnalysis: "Fiyat Analizi",
    usePrice: "Fiyatı Kullan",
    waybillInfo: "Satış Bilgileri",
    unitPurchasePrice: "Br. Alış Fiyatı",
    unitSellingPrice: "Br. Satış Fiyatı",
    unitCost: "Birim Maliyet",
    salesTargetFormatMustUsd: "*Satış rakamları kUSD cinsinden yazılmalıdır.",
    changeMonthlySalesTarget: "Aylık Satış Hedefleri değiştirildi.",
    isResponsible: "Sorumlu Mu?",
    isProjectTracking: "Proje Takibi Var Mı?",
    kpiCustomer: "KPI Müşteri",
    kpiLines: "KPI Üreticiler",
    otp: "OTP",
    weeklyRequiredVisitCount: "Haftalık Ziyaret Hedefi",
    weeklyRequiredVisitCountSales: "Yıllık Proje Hedefi",
    userSpecificSettings: "Kullanıcı Spesifik Ayarları",
    kpiLineResponsibility: "Kpi Üretici Sorumlulukları",
    realizationValue: "Gerçekleşme Değeri",
    realizationScore: "Gerçekleşme Puanı",
    weightedRealizationScore: "Ağırlıklı Gerçekleşme Puanı",
    activityCategoryResponsibility: "Aktivite Kategori Sorumluluğu",
    faxNumber: "Faks Numarası",
    invoiceAdress: "Fatura Adresi",
    shippingAdress: "Sevk Adresi",
    soleProprietorship: "Şahıs Şirketi",
    taxNoOrTcNo: "Vergi No veya TC No",
    currencyType: "Döviz Cinsi",
    salesCurrency: "Satış Kuru",
    projectFilters: "Proje Filtreleri",
    socketFilters: "Soket Filtreleri",
    purchaseAuthority: "Satın Alma Yetkisi",
    accountingAndFinanceOfficer: "Muhasebe/Finans Yetkilisi",
    rank: "Sorumluluk Alanı",
    country: "Ülke",
    province: "İl",
    district: "Semt",
    neighborhood: "Mahalle",
    accountingAndFinanceOfficerEmail: "Muhasebe/Finans Temsilcisi E-posta",
    accountingAgentEmail: "Muhasebe Temsilcisi E-Posta",
    purchasingAgentEmail: "Satın Alma Temsilcisi E-Posta",
    approvedBy: "Onaylayan Kişi",
    totalLifePotential: "Ömür Potansiyeli",
    customerCodeInfo:
      "Müşteri kodu, onaylandıktan sonra tekrar belirlenecektir. Müşteri oluşturulana kadar dikkate almayınız.",
    kepAdress: "Kep Adresi",
    shippingAdressControl: "Sevk adresi ve sipariş adresi aynı.",
    tradingGroup: "Uygulama Alanı",
    riskLimit: "Risk Limiti",
    trackGroup: "Takip Grubu",
    trackLotNumber: "Lot Numarası Takip Edilecek",
    trackSerialNumber: "Seri Numarası Takip Edilecek",
    specode: "Malzeme Cinsi",
    sectorGroup: "Sektör Bilgisi",
    estore: "E - Store",
    other: "Diğer",
    taxPlate: "Vergi Levhası",
    signatureCircular: "İmza Sirküsü",
    tradeRegistryGazette: "Ticaret Sicil Gazetesi",
    orderSupplier: "Sipariş Tedarikçisi",
    newProduct: "Yeni Ürün",
    serialNumber: "Seri Numarası",
    requestedQty: "Talep Miktarı",
    fiveYearPotential: "Beş Yıllık Potansiyel",
    currentYearPotential: "Mevcut Yıllık Potansiyel",
    currentYearSalesExpectation: "Mevcut Yıl Satış Beklentisi",
    potential: "Potansiyel",
    salesExpectation: "Satış Beklentisi",
    yearlySalesTarget: "Yıllık Satış Hedefi",
    yearlySales: "Yıllık Satış",
    taskType: "Görev Türü",
    clickToSeeMore: "Devamını Görmek İçin Tıklayınız",
    lastUpdate: "Son Güncelleme",
    characterLeft: "karakter kaldı",
    nothingEnhanced: "Enhance edilmiş bir şey yoktur.",
    changedMadeFollovs: "Yapılan değişiklikler şunlardır;",
    customerSegment: "Müşteri Segment",
    lineGroup: "Üretici Grubu",
    socket: "Soket",
    monthlySales: "Aylık Satış",
    monthlyOrder: "Aylık Sipariş",
    customerRequestDate: "Müşteri İstek Tarihi",
    shippingCountry: "Yükleme Ülkesi",
    deliveryAgent: "Teslimat Acentesi",
    numberOfQuoteGiven: "Verilen Teklif Sayısı",
    amountOfQuoteGiven: "Verilen Teklif Tutarı",
    amountOfOrdersReceived: "Alınan Sipariş Tutarı",
    orderConversionRate: "Sipariş Dönüşüm Oranı",
    numberOfQuote: "Teklif Sayısı",
    quoteValue: "Teklif Değeri",
    /** Menu */
    menuitems: {
      apps: "Uygulamalar",
      dashboard: "Ana Sayfa",
      activities: "Aktiviteler",
      activitiesApp: "Aktivite Uygulaması",
      quotes: "Teklifler",
      quotesApp: "Teklif Uygulaması",
      projects: "Projeler",
      projectsApp: "Proje Uygulaması",
      users: "Kullanıcılar",
      logout: "Çıkış Yap",
      notification: "Bildirimler",
      customers: "Müşteriler",
      products: "Ürünler",
      lines: "İlgili Üreticiler",
      sync: "Senkronizasyon",
      customerSync: "Müşteri Senkronizasyon",
      productSync: "Ürün Senkronizasyon",
      list: "Listeler",
      operations: "İşlemler",
      calculator: "Hesap Makinesi",
      tools: "Araçlar",
      logistics: "Lojistik",
      freeZoneOperations: "Serbest Bölge İşlemleri",
      purchaseOrders: "(SB) Satınalma İşlemleri",
    },
    months: {
      january: "Ocak",
      february: "Şubat",
      march: "Mart",
      april: "Nisan",
      may: "Mayıs",
      june: "Haziran",
      july: "Temmuz",
      august: "Ağustos",
      september: "Eylül",
      october: "Ekim",
      november: "Kasım",
      december: "Aralık",
    },
    days: {
      short: {
        monday: "Pzt",
        tuesday: "Sal",
        wednesday: "Çar",
        thursday: "Per",
        friday: "Cum",
        saturday: "Cmt",
        sunday: "Paz",
      },
    },
    /** Login Page */
    signIn: {
      title: "Giriş Yap",
      introText: "EmpaDigital'e tekrar hoş geldin!",
      button: "Giriş Yap",
      sendAgainCode: "Kod Gönder",
      otpInfo:
        "Lütfen mail adresinize gelen kodu giriniz. Eğer kod gelmediyse {second} saniye içinde 'Kod Gönder' butonuna tıklayarak tekrar deneyebilirsiniz.",
      otpInfoNowSendCode:
        "Lütfen mail adresinize gelen kodu giriniz. Eğer kod gelmediyse 'Kod Gönder' butonuna tıklayarak tekrar deneyebilirsiniz.",
      newCodeSendedMessage:
        "Yeni kod gönderilmiştir. Lütfen mail kutunuzu kontrol ediniz.",
    },

    /** Forgot Password? */
    forgotPassword: {
      title: "Parolanızı mı unuttunuz",
      introText: "Şifrenizi sıfırlamak için e-postanızı girin.",
      successMsg:
        "İsteğiniz başarıyla alındı. Lütfen e-postanızı kontrol ediniz.",
    },
    /**  Reset Password */
    resetPassword: {
      title: "Şifreyi Yenile",
      infoText:
        "Empadigital'e hoş geldiniz. Lütfen yeni bir şifre belirleyiniz.",
      button: "Şifreyi Yenile",
      successMsg:
        "Şifre başarıyla değiştirildi! Lütfen yeni şifreniz ile giriş yapınız.",
    },
    /** User Menu & 2FA */
    usermenu: {
      accountSettings: {
        title: "Hesap Ayarları",
        userInformation: "Kullanıcı Bilgileri",
        userName: "Kullanıcı Adı",
        firstName: "Adınız",
        lastName: "Soyadız",
        gsmNumbber: "GSM Numaranız",
        emailAddress: "E-posta Adresi",
        passwordUpdate: "Şifre Güncelleme",
        updateSuccessMsg: "Kullanıcı bilgileriniz güncellendi.",
        passwordUpdateSuccessMsg:
          "Şifreniz güncellendi. Lütfen yeni şifreniz ile giriş yapınız.",
      },
      twoFaDevices: {
        title: "2FA Cihazlarım",
        devicename: "Cihaz Adı",
        deviceStatus: "Cihaz Durumu",
        applicationLink: "Uygulama Linkleri",
        clickStore: "Uygulama linki için tıkla!",
        deviceInfo: "Cihaz Bilgileri",
        pincode: "Pin Kodu",
        message: {
          firstinfo:
            "2FA özelliğini akıllı telefonunuz ile kullanabilirsiniz. EmpaDigital'e her giriş işleminizde 2FA uygulaması üzerinden üretilen anlık kodu kullanmanız gerekmektedir. Akıllı telefonunuza erişiminiz olmadığında EmpaDigital'e giriş yapamazsınız. 2FA süreçleri ile ilgili sorularınız için lütfen yöneticinize danışınız. Akıllı telefonunuza Google Authenticator uygulamasını aşağıdaki linkleri ya da QR kodları kullanarak yükleyebilirsiniz.",
          secondinfo:
            "Google Authenticator uygulamasını aşağıdaki bağlantıları kullanarak veya QR kodlarını kullanarak akıllı telefonunuza yükleyebilirsiniz.",
          thirdinfo:
            " Uygulamayı yükledikten sonra cihazınıza vermek istediğiniz ismi yazıp 'BAŞLA' butonuna tıklamalısınız.",
          fourthinfo:
            "Aşağıdaki QR kodunu, telefonunuza yüklediğiniz Google Authenticator uygulamasına taratmalısınız. Sonrasında uygulamanın üreteceği bir kodu aşağıdaki alana girmelisiniz. İşlemi tamamladığınızda 2FA cihazınız aktifleşmiş olacak ve EmpaDigital'e tekrar giriş yapmanız gerekecek.",
        },
        successMsg:
          "Yeni 2FA cihazınız başarılı bir şekilde kayıt edilmiştir. Lütfen tekrar giriş yapınız.",
        delete: {
          modalTitle: "2FA Cihazımı Sil",
          warningMsg: "2FA cihazınızı silmek istediğinizden emin misiniz?",
          successMsg:
            "2FA cihazınız başarılı bir şekilde silinmiştir. Yeni bir 2FA cihazı eklemek için 24 saatiniz bulunmaktadır.",
        },
      },
      businessCard: {
        title: "Kartvizitim",
        qrcode: "Kartvizit QR Kod",
      },
      createPermissionForm: "İzin Formu Oluştur",
      tickets: "Talepler",
      createAnnouncement: "Duyuru Oluştur",
    },
    /** Notifications */
    notifications: {
      title: "Bildirimler",
      myNotifications: "Bildirimlerim",
      noNotificationsMessage: "Henüz bildirim yok",
      markAsRead: "Okundu Olarak İşaretlendi!",
      markAllInRead: "Tümünü Okundu Olarak İşaretle",
      unread: "Okunmamış",
      read: "Okunmuş",
      readStatus: "Okundu Bilgisi",
      permissionMessage:
        "Lütfen bildirimleri almak için yukarıda bulunan adres çubuğunun yanındaki bildirimler aracından izin veriniz.",
    },
    /** Dashboard */
    dashboards: {
      myDashboards: "Kontrol Panellerim",
      addNewDashboard: "Yeni Panel Ekle",
      noDataAvailable: "Veri bulunmamaktadır.",
      modal: {
        subtext: "Lütfen görüntülemek istediğiniz kontrol panelini seçin.",
      },
    },
    /** Activities */
    activities: {
      title: "Aktiviteler",
      newActivity: "Yeni Aktivite",
      totalActivity: "Toplam Aktivite Sayısı",
      addNewActivity: "Yeni Aktivite Oluştur",
      editActivity: "Aktiviteyi Düzenle",
      createNote: "Not Oluştur",
      createTask: "Görev Oluştur",
      searchActivityTag: "Aktivite Etiketi Ara...",
      projectNotDiscuss: "Proje konuşulmadı",
      notCompletedActivitiesWarningMsg:
        "Şu anda eksik notu bulunan aktiviteleri filtrelenmiş olarak görmektesiniz.",
      shortcuts: {
        today: "Bugün",
        yesterday: "Dün",
        aWeekAgo: "1 Hafta Önce",
      },
      importanceOptions: {
        firstStar:
          "(Tüm Empa Line ekibi ve Müşterinin Satış Sorumlusu Bilgilendirilecektir.)",
        secondStar:
          "(Tüm Empa Line ekibi, Müşterinin Satış Sorumlusu ve Tüm yöneticiler Bilgilendirilecektir.)",
        thirdStar:
          "(Tüm Empa Line ekibi, Müşterinin Satış Sorumlusu, Tüm yöneticiler ve Tüm Satış Ekibi Bilgilendirilecektir.)",
      },
      backPage: {
        warningMsg:
          "Yapılan değişiklikleriniz kaydedilmeyecektir, devam etmek istediğinize emin misiniz?",
      },
      add: {
        successMsg: "Yeni aktivite oluşturdu.",
        customerActivities: "Müşteriye ait aktiviteler",
      },
      edit: {
        successMsg: "Aktivite güncellendi.",
      },
      delete: {
        modalTitle: "Aktiviteyi Sil",
        warningMsg: "Aktiviteyi silmek istediğinizden emin misiniz?",
        successMsg: "Aktivite silme isteğiniz alınmıştır.",
      },
      notes: {
        infoText:
          "Notunuzun kayıt edilebilmesi için sizden istenilen bilgileri eksiksiz doldurunuz.",
        noNotes: "Not Bilgisi Bulunmamaktadır.",
        infoTextAutosave:
          "Notlarınızın otomatik kaydedilmesi için başlık girmeyi unutmayınız.",
      },
      task: {
        title: "Görevler",
        emptyTableText: "Bu aktiviteye ait task bulunmamaktadır.",
        add: {
          successMsg: "Yeni görev oluşturuldu.",
        },
      },
      files: {
        delete: {
          modalTitle: "Aktivite Dosyasını Sil",
          warningMsg: "Aktivite dosyasını silmek istediğinizden emin misiniz?",
          successMsg: "Dosya silindi.",
        },
      },
      noteSummary: {
        title: "Aktivite Notu Özeti",
      },
    },
    /** Quotes */
    quotes: {
      title: "Teklifler",
      newQuote: "Yeni Teklif",
      addNewQuote: "Yeni Teklif Oluştur",
      totalQuote: "Toplam Teklif Sayısı",
      totalQuoteAmount: "Toplam Teklif Tutarı",
      requireInternalCode: "Müşteri Internal Ürün Kodu Var Mı?",
      marginInfoText: "Vergiler ve Masraflar Öncesi Marj",
      noProductErrorMsg: "Teklif ürünü oluşturulmadan teklif oluşturulamaz!",
      requiredProductErrorMsg:
        "Lütfen ürün tablosunda ki zorunlu tutulan alanları( * ) eksiksiz bir şekilde doldurunuz ve ürün bilgilerin doğruluğundan emin olunuz.",
      backPage: {
        warningMsg:
          "Değişiklikleriniz kaydedilmeyecek, devam etmek istediğinizden emin misiniz?",
        productWarningMsg:
          "Ürün tablosunda ürünlerin bulunduğundan ve tamamının kaydedildiğinden emin olunuz.",
        noProductWarningMsg: "Lütfen ürün tablosuna ürün ekleyiniz.",
      },
      viewingRevision: "Revizyon {revision} Görüntüleniyor",
      quoteRevisions: "Teklif Revizyonları",
      noQuoteRevisions: "Teklif revizyonu bulunamadı.",
      add: {
        successMsg: "Yeni teklif oluşturdu.",
      },
      edit: {
        successMsg: "Teklif güncellendi.",
        editingQuote: "TEKLİFİ DÜZENLİYORSUNUZ",
      },
      revision: {
        title: "Revizyon Oluştur",
        successMsg: "Yeni revizyon oluşturdu.",
      },
      excel: {
        button: "Excel'den Veri Ekle",
        templateExcelDataButton: "Excel'den Veri Ekleme Taslağı",
        clearTable: "Tabloyu Temizle",
        excelDataNotFound: "Kopyalanmış excel verisi bulunamadı.",
        copiedDataFromExcel: "Excelden Kopyalanmış Veri Bulundu",
        copiedDataFromExcelMessage:
          "Otomatik olarak ürün tablosuna eklenmesini ister misin?",
      },
      product: {
        delete: {
          modalTitle: "Teklif Ürününü Sil",
          warningMsg: "Teklif ürününü silmek istediğinizden emin misiniz?",
          successMsg: "Teklif ürününü silindi.",
        },
        add: {
          successMsg: "Ürün kaydedildi.",
        },
        edit: {
          successMsg: "Ürün güncellendi.",
        },
      },
      task: {
        title: "Görevler",
        emptyTableText: "Bu teklife ait task bulunmamaktadır.",
        add: {
          successMsg: "Yeni görev oluşturuldu.",
        },
      },
    },
    /**Expo */
    expo: {
      title: "Fuarlar",
      newExpo: "Yeni Fuar",
      addNewExpo: "Yeni Fuar Oluştur",
      searchExpotitle: "Fuar Ara...",
      expoSummary: "Fuar Özeti",
      expoSummaryInfo: "Rakamlar, bölümler, tarihçe, izlenimler vs.",
      expoMediaInfo: "Fuar ile ilgili fotoğraflar.",
      expoMedia: "Fuar Fotoğrafları",
      expoActivityRecords: "Aktivite Kayıtları",
      expoActivityRecordsInfo: "Görüşmeler veya stant ziyaretleri.",
      withOurOwnSuppliers: "Kendi Üreticilerimiz",
      withPotentialSuppliers: "Potansiyel Üreticiler",
      withCompetitors: "Rakiplerle",
      withCustomers: "Müşterilerle",
      addExpoPhoto: "Fuar Fotoğrafı Ekle",
      expoTagTooltipText:
        "Etkinliğin Aktivite, Proje ve Teklif gibi modüllerde takip edilebilmesi için oluşturulan bir etikettir. Burada oluşturulan etiket bahsi geçen modüllerde seçilebilir olacaktır. Etiket isimlendirmesi için aşağıdaki örneklere uygun bir format kullanınız.",
      tableHeader: {
        name: "Fuar Adı",
        location: "Fuar Yeri",
        date: "Tarih",
        expoWebsite: "Website",
      },
      plan: {
        add: {
          modalTitle: "Plan Ekle",
          successMsg: "Yeni plan oluşturdu.",
        },
        edit: {
          modalTitle: "Planı Düzenle",
          successMsg: "Fuar planı düzenlendi.",
        },
      },
      add: {
        successMsg: "Yeni fuar oluşturuldu.",
      },
      report: {
        downloadingReport: "Raporlar indiriliyor...",
        deleteActivityRequestMsg: "Aktivite silme isteği bekleniyor.",
        update: {
          successMsg: "Fuar raporu güncellendi.",
        },
        newActivityRecord: {
          successMsg: "Yeni aktivite kaydı ve aktivite oluşturuldu.",
        },
        updateActivityRecord: {
          successMsg: "Aktivite kaydı güncellendi.",
        },
        deleteReport: {
          modalTitle: "Raporu Sil",
          warningMsg: "Rapor silinecek. Bu işlemi onaylıyor musunuz?",
          successMsg: "Fuar raporu silindi.",
        },
        deleteNote: {
          modalTitle: "Notu Sil",
          warningMsg: "Not silinecek. Bu işlemi onaylıyor musunuz?",
          successMsg: "Not başarıyla silindi.",
        },
      },
    },
    /** Projects */
    projects: {
      title: "Projeler",
      newProject: "Yeni Proje",
      addNewProject: "Yeni Proje Oluştur",
      projectHistory: "Proje Geçmişi",
      noProjectHistory: "Proje geçmişi bulunamadı.",
      noProjectActivityHistory: "Aktivite geçmişi bulunamadı.",
      noProjectSampleHistory: "Numune geçmişi bulunamadı.",
      totalProject: "Toplam Proje Sayısı",
      setLostWarningMsg: "Ürünü 'LOST' konumuna getir.",
      sockets: "Soketler",
      fiveYearlyValues: "5 Yıllık Değerler",
      activeSockets: "Aktif Soketler",
      winRateTooltip:
        "Ürününüzün projede tasarıma girme ihtimali. 1-En düşük, 3-En yüksek.",
      addProject: {
        customer: "Müşteri",
        selectCustomer: "Müşteri seçiniz",
        customerProject: "Müşteriye ait projeler",
      },
      add: {
        successMsg: "Yeni proje oluşturdu.",
      },
      edit: {
        successMsg: "Proje güncellendi.",
        editingProject: "PROJESİNİ DÜZENLİYORSUNUZ",
      },
      backPage: {
        warningMsg:
          "Yapılan değişiklikleriniz kaydedilmeyecektir, devam etmek istediğinize emin misiniz?",
      },
      copyProject: {
        warningMsg:
          "Proje kopyalama işlemi gerçekleştirilecektir. Devam etmek istiyor musunuz?",
      },
      note: {
        add: { successMsg: "Yeni not oluşturuldu." },
        edit: { successMsg: "Not düzenlendi." },
        delete: {
          modalTitle: "Notu Sil",
          warningMsg: "Not silinecek. Bu işlemi onaylıyor musunuz?",
          successMsg: "Not silindi.",
        },
      },
      delete: {
        modalTitle: "Projeyi Sil",
        warningMsg: "Projeyi silmek istediğinizden emin misiniz?",
        successMsg: "Proje silme isteğiniz alınmıştır.",
      },
      product: {
        warningMsg:
          "Proje içerisinde en az 1 ürün bulunmak zorundadır. Aksi durumda projeye silme isteği gönderebilirsiniz.",
        delete: {
          modalTitle: "Proje Ürününü Sil",
          warningMsg: "Proje ürününü silmek istediğinizden emin misiniz?",
          successMsg: "Proje ürününü silindi.",
        },
        add: {
          successMsg: "Ürün kaydedildi.",
        },
        edit: {
          successMsg: "Ürün güncellendi.",
        },
        note: {
          lostDescriptionMsg: "Ürünün LOST sebebi notlardadır.",
          add: {
            modalTitle: "LOST Ürün",
            warningMsg:
              "LOST konumuna getirilen ürünler bir daha düzenlenemez ve silinemez.",
            infoLabel:
              "{product} ürününü LOST konumuna alma sebebinizi giriniz:",
            successMsg: "Yeni not oluşturuldu.",
          },
        },
      },
      task: {
        title: "Görevler",
        emptyTableText: "Bu projeye ait task bulunmamaktadır.",
        add: {
          successMsg: "Yeni görev oluşturuldu.",
        },
      },
      files: {
        delete: {
          modalTitle: "Proje Dosyasını Sil",
          warningMsg: "Projeye dosyasını silmek istediğinizden emin misiniz?",
          successMsg: "Dosya silindi.",
        },
      },
    },
    /** Customers */
    customers: {
      title: "Müşteriler",
      view: "Müşteri Detayı",
      paymentReceipt: "Tahsilat Karnesi",
      customerSummary: "Müşteri Özeti",
      visitSuggestions: "Ziyaret Önerileri",
      issuesAwaitingAction: "Aksiyon Bekleyen Konular",
      customerName: "Müşteri Adı",
      addNewCustomer: "Yeni Müşteri Ekle",
      favorite: "Takip Ettiklerim",
      authoritytable: { warningMsg: "Müşteri yetkisi bulunmamaktadır." },
      activitytable: {
        warningMsg: "Müşteriye ait aktivite bulunmamaktadır.",
      },
      quotetable: { warningMsg: "Müşteriye ait teklif bulunmamaktadır." },
      projectstable: { warningMsg: "Müşteriye ait proje bulunmamaktadır." },
      newCustomerAuth: "Yeni Müşteri Yetkisi",
      addCustomer: {
        successMsg: "Yeni müşteri oluşturuldu.",
      },
      followCustomer: {
        successMsg: "Müşteri takip ediliyor.",
      },
      unfollowCustomer: {
        successMsg: "Müşteriyi artık takip etmiyorsun.",
      },
      searchCustmer: "Müşteri Ara",
      customerViewMessage: "Lütfen görüntülemek istediğiniz müşteriyi seçiniz.",
      noContact: "Müşteriye ait iletişim bilgileri bulunmamaktadır.",
      noAddress: "Müşteriye ait adres bilgileri bulunmamaktadır.",
      customerCheckSimilarityMsg:
        "Oluşturmaya çalıştığınız müşteri ismiyle benzer müşteriler mevcut. Bunlardan biri olabilir mi?",
      customerContact: {
        exampleContactInfo: "Örnek Kontak Bilgisi",
        pleaseContactInput:
          "Lütfen bilgileri örnek formattaki gibi sadece ilk harfi büyük olarak doldurunuz.",
        add: {
          modalTitle: "Yeni Müşteri Kontağı Oluştur",
          successMsg: "Yeni müşteri kontağı oluşturuldu.",
        },
        edit: {
          modalTitle: "Müşteri Kontağını Düzenle",
          successMsg: "Müşteri kontağı düzenlendi.",
        },
        isActive: {
          modalTitle: "Müşteri Kontağını Düzenle",
          warningMsg:
            "Bu kontağı aktif/pasif yapmak istediğinize emin misiniz?",
          successMsg: "Müşteri kontağı güncellendi.",
        },
      },
      contacts: {
        add: {
          button: "Yeni Kontak",
          modalTitle: "Yeni Kontak Ekle",
          successMsg: "Yeni Kontak eklendi.",
        },
        edit: {
          successMsg: "Kontak güncellendi.",
        },
      },
      address: {
        add: {
          modalTitle: "Yeni Müşteri Adresi Oluştur",
          successMsg: "Yeni müşteri adresi oluşturuldu.",
        },
      },
      tooltip: {
        salesInfo:
          "Müşterinin satışçısı için belirlenmiş yıllık ziyaret hedefi. Sadece satışçının katıldığı ziyaretler sayılmaktadır.",
        marketingInfo:
          "Müşterinin pazarlama ekibi için belirlenmiş yıllık ziyaret hedefi. Sadece pazarlama ekibinin katıldığı ziyaretler sayılmaktadır.",
        ytdSalesInfo: "Mevcut yılın başından beri yapılan satış miktarı.",
        ytdBookingInfo: "Mevcut yılın başından beri alınan sipariş miktarı.",
        bibaInfo:
          "Billing + Backlog, YTD Satış değeri ile mevcut yıla girecek olan Backlog'un toplamı.",
      },
    },
    /** Products */
    products: {
      title: "Ürünler",
      view: "Ürün Detayı",
      add: {
        modalTitle: "Yeni Ürün Oluştur",
        successMsg: "Yeni ürün oluşturuldu.",
      },
    },
    /** Lines */
    lines: {
      title: "Üreticiler",
      view: "Üretici Detayı",
      searchLine: "Üretici Ara",
      lineViewMessage: "Lütfen görüntülemek istediğiniz üreticiyi seçiniz.",
      noNote: "Üreticiye ait not bulunamadı.",
      noAddress: "Üreticiye ait adres bulunamadı.",
      noContact: "Üreticiye ait iletişim bilgileri bulunmamaktadır.",
      noMinOrderValue: "Üreticiye ait 'Min. Sipariş Değeri' bulunmamaktadır.",
      paymentTerm: {
        edit: {
          successMsg: "Ödeme vadesi güncellendi.",
        },
      },
      shipmentType: {
        edit: {
          successMsg: "Sevkiyat tipi güncellendi.",
        },
      },
      minOrderValue: {
        edit: {
          successMsg: "Min. Sipariş Değeri güncellendi.",
        },
      },
      note: {
        lineNote: "Üretici Notu",
        add: {
          button: "Not Ekle",
          successMsg: "Üretici notu eklendi.",
        },
        edit: {
          successMsg: "Üretici notu düzenlendi.",
        },
      },
      address: {
        add: {
          button: "Adres Ekle",
          modalTitle: "Üretici Adresi Ekle",
          successMsg: "Yeni adres oluşturuldu.",
        },
        edit: {
          modalTitle: "Üretici Adresini Düzenle",
          successMsg: "Adres güncellendi.",
        },
      },
      contacts: {
        add: {
          button: "Kontak Ekle",
          modalTitle: "Üretici Kontağı Ekle",
          successMsg: "Yeni kontak oluşturuldu.",
        },
        isActive: {
          modalTitle: "Üretici Kontağını Düzenle",
          warningMsg:
            "Bu kontağı aktif/pasif yapmak istediğinize emin misiniz?",
          successMsg: "Üretici kontağı güncellendi.",
        },
        edit: {
          modalTitle: "Üretici Kontağını Düzenle",
          successMsg: "Üretici kontağı düzenlendi.",
        },
      },
    },
    /** Users */
    users: {
      title: "Kullanıcılar",
      newUser: "Yeni Kullanıcı",
      addNewUser: "Yeni Kullanıcı Ekle",
      editUser: "Kullanıcı Düzenle",
      profiles: {
        profile: "PROFİL",
        faeProfile: "FAE PROFİLİ",
        accManagerProfil: "SATIŞÇI PROFİLİ",
      },
      add: {
        successMsg: "Yeni kullanıcı oluşturuldu.",
      },
      edit: {
        successMsg: "Kullanıcı güncellendi.",
      },
      deactive: {
        modalTitle: "Kullanıcıyı Devre Dışı Bırak",
        warningMsg:
          "Kullanıcı devre dışı bırakılacaktır. Bu işlemi onaylıyor musunuz?",
        successMsg: "Kullanıcı devre dışı bıraklıdı.",
      },
      active: {
        modalTitle: "Kullanıcıyı Aktif Et",
        warningMsg: "Kullanıcı aktif edilecektir. Bu işlemi onaylıyor musunuz?",
        successMsg: "Kullanıcı aktif edildi.",
      },
      adminChangePassword: {
        modalTitle: "Kullanıcı Şifresi Değiştir",
        warningMsg:
          "Kullanıcı şifresi değiştirilecektir. Bu işlemi onaylıyor musunuz?",
        successMsg: "Kullanıcı şifresi başarıyla değiştrildi!",
      },
      selectUserRoleWarningMsg:
        "Lütfen kullanıcının görüntülemek istediğiniz rolünü seçiniz.",
      tabs: {
        general: "Genel",
        kpiMetrics: "KPI Ölçütler",
        kpiManagement: "KPI Yönetim",
        kpiAssessment: "KPI Yetkinlik Değerlendirme",
      },
      kpiCustomers: {
        kpiResponsibleWarningMsg:
          "Bu müşteri mevcut KPI dönemi için '{user}'in sorumluluğundadır. Devam etmek istediğinize emin misiniz?",
      },
      kpiLines: {
        edit: {
          successMsg: "Kpi Lines listesi güncellendi.",
        },
      },
      kpiMetrics: {
        emptyDataWarningMsg:
          "Uygun KPI ölçütü Bulunamadı. Lütfen yöneticiniz ile iletişime geçiniz.",
      },
      kpiAssessment: {
        employeeAssesment: "Çalışan Değerendirmesi",
        managerAssesment: "Yönetici Değerlendirmesi",
        employeeComment: "Çalışan Yorumu",
        managerComment: "Yönetici Yorumu",
        userTotalScore: "Çalışanın Toplam Skoru",
        managerTotalScore: "Yetkinlik Performans Skoru",
        notFoundMessage:
          "Değerlendirme Bulunamadı. Eski bir tarih veya daha zamanı gelmemiş bir değerlendirme zamanı seçimi yapmış olabilirsiniz.",
        half1: "H1",
        endOfTheYear: "Yıl Sonu",
      },
    },
    userRoles: {
      ROLE_FAE: "FAE",
      ROLE_ACCOUNT_MANAGER: "Satış Temsilcisi",
      ROLE_MANAGER: "Yönetici",
      ROLE_LINE_MANAGER: "Ürün Temsilcisi",
      ROLE_SUPER: "Süper",
      ROLE_SHIPMENT_USER: "Sevkiyat Çalışanı",
      ROLE_CUSTOMER_REPRESENTATIVE: "Müşteri Temsilcisi",
      ROLE_CALENDAR_ADMIN: "Takvim Yöneticisi",
      ROLE_IT_USER: "IT Yöneticisi",
      ROLE_TOP_MANAGER: "Üst Yönetici",
      ROLE_READ_ONLY: "Sadece Okuma Yetkisi",
      ROLE_FINANCE_USER: "Muhasebe Çalışanı",
    },
    secondaryRole: "İkinci Rol",
    secondaryRoleMsg:
      "İkincil rol, kullanıcıların sadece spesifik bir alanda yetki sahibi olmalarını sağlamaktadır. Örneğin: ELK Depo Sorumlusu tek başına kullanılamaz. 2 Farklı alanda depo sahibi olduğumuz için depo kullanıcılarını ayırmak için kullanılmalıdır.",
    //** Logistics */
    logistics: {
      title: "Lojistik",
      freeZoneOperations: "Serbest Bölge İşlemleri",
      trackNo: "Takip Numarası",
      invoiceNumber: "Fatura Numarası",
      date: "Tarih",
      productCode: "Ürün Kodu",
      GTIP: "GTIP",
      totalAmount: "Toplam Miktar",
      unit: "Birim",
      totalPrice: "Toplam Fiyat",
      origin: "Menşei",
      shippingCountry: "Nakliye Ülkesi",
      retrieveInvoices: "Faturaları Al",
      compareWithFile: "Dosya ile Karşılaştır",
      compareWithFileInfo: "Serbest Bölge İş Emri Dosyası Karşılaştırması",
      isValidMessage: "Tüm bilgiler geçerlidir.",
      isInValidMessage: "Geçersiz satırlar algılandı.",
      checkTable: "Lütfen tabloyu kontrol edin.",
      palletCount: "Platelet Sayısı",
      weight: "Toplam Ağırlık",
      startProcess: "İşlemi Başlat",
      totalMissing: "Toplam Eksik",
      purchaseOrders: "(SB) Satınalma İşlemleri",
      purchaseOrderShipments: "Satınalma Sipariş Gönderileri",
      supplier: "Tedarikçi",
      purchaseOrderShipmentsStatus: {
        "0": "BAŞLANGIÇ",
        "1": "GÖNDERİ BEKLENİYOR",
        "2": "GÖNDERİ DEVAM ETMEKTEDİR",
        "3": "GÖNDERİ TAMAMLANDI",
        "-1": "SEVKİYAT HATASI",
      },
      deletePurchaseOrder: "Satınalma Siparişini Sil",
      deletePurchaseOrderWarningMsg:
        "Satınalma işlemlemini silmek istediğinizden emin misiniz?",
      deletePurchaseOrderSuccessMsg: "Satınalma siparişi silindi.",
      productInformation: "Ürün Bilgileri",
      totalNumberOfEnteredProducts: "Toplam Girişi Yapılmış Ürün Sayısı",
      boxInformation: "Koli Bilgileri",
      createPackage: "Koli Oluştur",
      productionDate: "Üretim Tarihi",
      batchNo: "Lot/Batch No",
      packedId: "Packed ID",
      boxCount: "Kutu Sayısı",
      saveAndGenerateQR: "Kaydet ve QR Oluştur",
      purchaseOrderItemStatus: {
        "0": "BEKLENİYOR",
        "1": "DEVAM EDİYOR",
        "2": "TAMAMLANDI",
        "-1": "HATA",
      },
      price: "Fiyat",
    },
    /** Sync */
    sync: {
      customer: {
        warningInfo:
          "{from_customer_pk} müşterisi {to_customer_pk} müşterisine dönüştürülecektir.",
        successMsg: "Müşteri senkronizasyonu başarılı bir şekilde yapıldı.",
      },
      product: {
        warningInfo:
          "{from_product_pk} ürünü {to_product_pk} ürününe dönüştürülecektir.",
        successMsg: "Ürün senkronizasyonu başarılı bir şekilde yapıldı.",
      },
      projectName: "Proje İsmi",
      activityCategory: "Aktivite Kategorisi",
      quoteHeadline: "Teklif Başlığı",
      oldCustomer: "Eski Müşterisi",
      oldProduct: "Eski Ürün",
      projectsToBeAffected: "Etkilenecek Projeler",
      activitiesToBeAffected: "Etkilenecek Aktiviteler",
      quotesToBeAffected: "Etkilenecek Teklifler",
      sampleToBeAffected: "Etkilenecek Numuneler",
      projectsToBeAffectedForProduct: "Bu dönüşüm {count} projeyi etkiler.",
    },
    /** Synchronizations */
    synchronizations: {
      title: "Senkronizasyonlar",
      logoSynchronizations: "Logo Senkronizasyonlar",
      importTasks: "İçe Aktarma Görevleri",
      importTypes: {
        LIST_PRICE: "Liste Fiyatları",
      },
    },
    /** Dictionary */
    dictionary: {
      title: "Sözlük",
      newWord: "Yeni Kelime",
      addNewWord: "Yeni Kelime Ekle",
      editWord: "Kelimeyi Düzenle",
      addWordSuccessMsg: "Yeni kelime başarıyla eklendi.",
      editWordSuccessMsg: "Kelime başarıyla güncellendi.",
      deleteWordSuccessMsg: "Kelime başarıyla silindi.",
      delete: {
        modalTitle: "Kelime Siliniyor",
        warningMsg: "Kelime silinecek. Bu işlemi onaylıyor musunuz?",
      },
    },
    /** Storage */
    storage: {
      title: "Kişisel Depolama Alanı",
      foldersTitle: "Klasörler",
      uploadFiles: "Dosya Yükle",
      allowImageTypes: "jpg/png/jpeg/webp formatlarına izin verilir.",
      giveADirectoryName: "Klasör Adı",
      deleteFolder: "Klasörü Sil",
      searchFolders: "Klasör Ara",
      noSelectedFolder: "Klasör seçilmedi.",
      noSelectedFile: "Dosya seçilmedi.",
      chooseAFolder: "Bir klasör seçiniz.",
      expiresAtMsg: "Bu dosyalar {date} tarihinden sonra indirilemeyecektir.",
      legalNotice: "Yasal Uyarı",
      delete: {
        warningMsg: "Bu klasörü silmek istediğinizden emin misiniz?",
        successMsg: "Klasör silindi.",
      },
      deleteFile: {
        title: "Dosya(ları) Sil",
        warningMsg: "Seçili dosya(ları) silmek istediğinizden emin misiniz?",
        successMsg: "Dosya(lar) silindi.",
      },
      shareFile: {
        title: "Dosya(ları) Paylaş",
        selectedFiles: "Seçili Dosya(lar)",
      },
      shareFolder: {
        title: "Klasörü Paylaş",
        selectedFolders: "Seçili Klasör",
      },
      downloadFile: {
        downloadingFile: "Dosya(lar) indiriliyor...",
        downloadedFile: "Dosya(lar) indirildi.",
        notDownloadFile: "Dosya(lar) indirilemedi.",
      },
      add: {
        addNewFolder: "Yeni Klasör Ekle",
        successMsg: "Yeni klasör oluşturuldu.",
      },
    },
    /** Samples */
    samples: {
      title: "Numuneler",
      newSample: "Yeni Numune Formu",
      addNewSample: "Yeni Numune Formu Oluştur",
      noProductErrorMsg: "Ürün eklenmeden numune formu oluşturulamaz!",
      requiredProductErrorMsg:
        "Lütfen ürün tablosunda ki zorunlu tutulan alanları( * ) eksiksiz bir şekilde doldurunuz ve ürün bilgilerin doğruluğundan emin olunuz.",
      thoseInProgress: "Devam Edenler",
      boxCount: "Kap Numarası",
      totalBoxCount: "Toplam Kap",
      status: {
        WAITING_CUSTOMER_REPRESENTATIVE: "Müşteri Temsilcisi Bekleniyor",
        WAITING_IMEI_CONTROL: "IMEI Girişi Bekleniyor",
        WAITING_WAYBILL_COMPLETE: "İrsaliyenin Tamamlanması Bekleniyor",
        WAITING_STORAGE_DUMPSTER: "KAP Bilgisi Bekleniyor",
        WAITING_FINANCE_SUPERVISOR: "Muhasebe Sorumlusu Bekleniyor",
        WAITING_SHIPMENT: "Sevkiyat Bekleniyor",
        WAITING_DIRECT_SHIPMENT: "Direkt Numunenin Sevki Bekleniyor",
        CANCELLED_SAMPLE: "Numune İptal Edildi",
        CANCELLED_DIRECT_SHIPMENT: "Direkt Numune İptal Edildi",
        COMPLETED_SHIPMENT: "Sevkiyat Tamamlandı",
        COMPLETED_DIRECT_SHIPMENT: "Direkt Numune Sevkiyatı Tamamlandı",
      },
      formType: {
        default: "Müşteri",
        personal: "Şahıs",
        direct: "Direkt",
      },
      add: {
        direct: {
          title: "Direkt",
          text: "Numuneler stoklarımıza giriş yapmadan direkt olarak müşteriye gönderilecek ise bu form ile gönderilmelidir.",
        },
        personal: {
          title: "Şahıs Adına",
          text: "Numunelerin gönderileceği kişi müşterimiz değilse bu form ile gönderilmelidir.",
        },
        default: {
          title: "Müşteri Adına",
          text: "Numuneler stoklarımızdan müşterilerimize gönderilecek ise bu form ile gönderilmelidir.",
        },
        successMsg: "Yeni numune formu oluşturuldu.",
        deliveryInformationWarningMsg:
          "Lütfen gerekli teslimat bilgilerini eksiksiz doldurunuz.",
        hasNoCurrentRecordErrorMsg:
          "Seçilen müşterinin cari kaydı bulunmamaktadır.",
        hasNoCurrentRecordErrorMsgInfo:
          "Müşteri için cari kayıt oluşturun ya da kontak kişi adına şahsi fatura kesilmesi için 'TC Kimlik Numarası' ve 'Telefon Numarası' bilgilerini eksiksiz doldurunuz.",
        hasNoCurrentRecordWarningMsg:
          "Seçilen müşterinin cari kaydı bulunmamaktadır.",
        hasNoCurrentRecordWarningMsgInfo:
          "Teslimat bilgilerinde yer alan kontak bilgilerini kullanarak Numune Carisine irsaliye kesiniz.",
      },
      confirm: {
        modalTitle: "Numune Formunu Onayla",
        warningMsg: "Numune Formunu onaylamak istediğinize emin misiniz?",
        successMsg: "Numune Formu onaylandı.",
      },
      cancel: {
        modalTitle: "Numune Formunu İptal Et",
        cancellationReason: "İptal Nedeni",
        warningMsg: "Numune Formunu iptal etmek istediğinize emin misiniz?",
        successMsg: "Numune Formu iptal edildi.",
      },
      edit: {
        successMsg: "Numune formu güncellendi.",
        editingSampleForm: "NUMUNE FORMUNU DÜZENLİYORSUNUZ",
      },
      note: {
        add: {
          modalTitle: "Yeni Numune Notu Ekle",
          successMsg: "Yeni numune notu oluşturuldu.",
        },
        edit: {
          modalTitle: "Numune Notunu Düzenle",
          successMsg: "Numune notu düzenlendi.",
        },
        delete: {
          modalTitle: "Numune Notunu Sil",
          warningMsg: "Numune notunu silmek istediğinizden emin misiniz?",
          successMsg: "Numune notu silindi.",
        },
      },
      sequential: {
        errorMsg:
          "Lütfen ürün tablosunda ki zorunlu tutulan alanları( * ) eksiksiz bir şekilde doldurunuz ve kap numarasının 1 den başlayıp eksik olmayan ardışık rakamları girdiğinizden emin olun.",
      },
      product: {
        warningMsg:
          "Numune içerisinde en az 1 ürün bulunmak zorundadır. Aksi durumda numuneye silme isteği gönderebilirsiniz.",
        add: {
          successMsg: "Yeni numune ürününü oluşturuldu.",
        },
        edit: {
          successMsg: "Numune ürününü düzenlendi.",
        },
        delete: {
          modalTitle: "Numune Ürününü Sil",
          warningMsg: "Numune ürününü silmek istediğinizden emin misiniz?",
          successMsg: "Numune ürününü silindi.",
        },
      },
    },
    /** Price Calculator */
    priceCalculator: {
      header: "Fiyat Hesaplama",
      tabs: {
        marginCalculate: "Marj Hesapla",
        purchaseCalculate: "Alış Fiyatı Hesapla",
        saleCalculate: "Satış Fiyatı Hesapla",
      },
      label: {
        margin: "Marj",
        purchasePrice: "Alış Fiyatı",
        salePrice: "Satış Fiyatı",
      },
    },
    /** Target */
    targets: {
      title: "Yıllık Hedefler",
      target: "Hedefi",
      sales: "Satış",
      addCustomer: "Müşteri Ekle",
      addCustomerToTarget: "Add Customer to Target",
      rollingExpTotal: "Yıllık Proje Pot.",
      companySalesTarget: "Şirket Satış Hedefi",
      personalSalesTarget: "Filtrelenen Satış Hedefi",
      companyProfitTarget: "Şirket Kar Hedefi",
      personalProfitTarget: "Filtrelenen Kar Hedefi",
      personalTargetedCustomers: "Filtrelenen Hedef Müşteri",
      targetedCustomers: "Hedeflenen Müşteriler",
      totalSales: "Toplam Satış",
      totalProfit: "Toplam Kar",
      salesTarget: "Satış Hedefi",
      profitTarget: "Kar Hedefi",
      salesVisitTarget: "Satış Ziyaret Hedefi",
      marketingVisitTarget: "Pazarlama Ziyaret Hedefi",
      actualSalesVisitTarget: "Gerçekleşen Satış Ziyaret Hedefi",
      actualMarketingVisitTarget: "Gerçekleşen Pazarlama Ziyaret Hedefi",
      pendingApproval: "Onay Bekleyen",
      previousYears: "Geçmiş Yıllar",
      myCustomerOnly: "Sadece Müşterim",
      targetErrorMsg:
        "Müşterinin Aylık Hedefleri, Yıllık Hedefi ile eşit değildir.",
      targetSuccessMsg: "Müşterinin Aylık Hedefleri girilmiştir.",
      users: {
        userVisitTargets: "Kullanıcı Ziyaret Hedefleri",
        webcallTarget: "Webcall Hedefi",
        visitTarget: "Ziyaret Hedefi",
        add: {
          successMsg: "İşlem Başarılı! Yeni kullanıcı hedefi oluşturuldu.",
        },
        edit: {
          successMsg: "İşlem Başarılı! Kullanıcı hedefi güncellendi.",
        },
        delete: {
          modalTitle: "Kullanıcı Yıllık Hedefini Sil",
          warningMsg:
            "Kullanıcıya ait yıllık hedefi silmek istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Kullanıcı hedefi silindi.",
        },
      },
      lines: {
        lineAnnualTargets: "Üretici Yıllık Hedefleri",
        lineMonthlyTargets: "Üretici Aylık Hedefleri",
        customerMonthlyTargets: "Müşteri Aylık Hedefleri",
        values: "Değerler",
        customerSalesAndProfitTargets: "Müşteri SATIŞ/KAR Hedefleri",
        monthly: {
          salesTargetErrorMsg:
            "Aylık Satış Toplamı, Yıllık Satış Toplamına Eşit Olmalıdır.",
          profitTargetErrorMsg:
            "Aylık Kar Toplamı, Yıllık Kar Toplamına Eşit Olmalıdır.",
          edit: {
            successMsg:
              "İşlem Başarılı! Üretici Aylık Hedef tablosu güncellendi.",
          },
        },
        add: {
          successMsg: "İşlem Başarılı! Yeni hedef oluşturuldu.",
        },
        edit: {
          successMsg: "İşlem Başarılı! Hedef güncellendi.",
        },
        delete: {
          modalTitle: "Üretici Yıllık Hedefini Sil",
          warningMsg:
            "Üreticiye ait yıllık hedefi silmek istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Hedef silindi.",
        },
      },
    },
    /** Reports */
    reports: {
      title: "Raporlar",
      reportNotFound: "Rapor bulunamadı.",
      activityReport: "Aktivite Raporu",
      marketingPerformanceReport: "Pazarlama Ekibi Performans Raporu",
      legends: {
        red: "Girilmeyen aktivite notlarının sayısı.",
        orange: "Geç girilen aktivite notlarının sayısı.",
        green: "Zamanında girilen aktivite notlarının sayısı.",
      },
    },
    /** Approvals */
    approvals: {
      title: "Onaylar",
      targets: {
        title: "Hedef Modülü Onayları",
        tableEmptyText: "Hedef modülüne ait onay bulunmamaktadır.",
        approve: {
          warningMsg: "Bu hedefi onaylamak istediğinizden emin misiniz?",
          selectedWarningMsg:
            "Seçili hedefleri onaylamak istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Hedef onaylandı.",
        },
        reject: {
          warningMsg: "Bu hedefi reddetmek istediğinizden emin misiniz?",
          selectedWarningMsg:
            "Seçili hedefleri reddetmek istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Hedef reddedildi.",
        },
      },
      projects: {
        title: "Proje Silme Onayları",
        tableEmptyText:
          "Proje modülüne ait proje silme isteği bulunmamaktadır.",
        approve: {
          warningMsg:
            "Proje silme isteğini onaylamak istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Proje silme isteği onaylandı.",
        },
        reject: {
          warningMsg:
            "Proje silme isteğini reddetmek istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Proje silme isteği reddedildi.",
        },
      },
      activities: {
        title: "Aktivite Silme Onayları",
        tableEmptyText:
          "Aktivite modülüne ait aktivite silme isteği bulunmamaktadır.",
        approve: {
          warningMsg:
            "Aktivite silme isteğini onaylamak istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Aktivite silme isteği onaylandı.",
        },
        reject: {
          warningMsg:
            "Aktivite silme isteğini reddetmek istediğinizden emin misiniz?",
          successMsg: "İşlem Başarılı! Aktivite silme isteği reddedildi.",
        },
      },
    },
    /** POF */
    pof: {
      title: "Satın Alma & Teklif Takip",
      newPOF: "Yeni POF",
      selectedOuoteInfoText: "{count} teklif seçildi.",
      createButton: "POF Oluştur",
      downloadButton: "POF İndir",
      divideIntoUnits: "Adetlere Böl",
      productInvalidErrorMsg:
        "Lütfen 'Satın Alma' ve 'Müşteriye Teslimat' tablolarındaki zorunlu alanları (*) doldurduğunuzdan ve değişiklikleri kaydettiğinizden emin olunuz.",
      status: {
        CANCELLED: "İptal Edildi",
        COMPLETED: "Tamamlandı",
        APPROVED_PURCHASING: "Satın Alma Onayladı",
        APPROVED_CUSTOMER_REPRESENTATIVE: "Müşteri Temsilcisi Onayladı",
        WAITING_PURCHASING: "Satın Alma Bekleniyor",
        WAITING_CUSTOMER_REPRESENTATIVE: "Müşteri Temsilcisi Bekleniyor",
        DRAFT: "Taslak",
      },
      logoCreationStatusMsg: {
        customer:
          "Şiparişi onaylayabilmek için müşteri bilgilerinin girilmesi zorunludur.",
        product:
          "Şiparişi onaylayabilmek için ürün bilgilerinin girilmesi zorunludur.",
      },
      add: {
        loadingText: "Satın Alma Formu oluşturuluyor...",
        successMsg: "Satın Alma Formu oluşturuldu.",
      },
      edit: {
        successMsg: "Satın Alma Formu düzenlendi.",
        productInvalidErrorMsg:
          "Lütfen ürün listesinde zorunlu alanları (*) doldurduğunuzdan ve değişiklikleri kaydettiğinizden emin olunuz.",
      },
      confirm: {
        modalTitle: "Satın Alma Formuni Onayla",
        warningMsg: "Satın Alma Formunu onaylamak istediğinize emin misiniz?",
        successMsg: "Satın Alma Formu onaylandı.",
      },
      cancel: {
        modalTitle: "Satın Alma Formunu İptal Et",
        cancellationReason: "İptal Nedeni",
        warningMsg: "Satın Alma Formunu iptal etmek istediğinize emin misiniz?",
        successMsg: "Satın Alma Formu iptal edildi.",
      },
      attachments: {
        add: {
          successMsg: "Satın Alma Formuna yeni dosya eklendi.",
        },
        edit: {
          successMsg: "Dosya düzenlendi.",
        },
        delete: {
          modalTitle: "Dosyayı Sil",
          warningMsg:
            "Satın Alma Formuna ait dosyayı silmek istediğinizden emin misiniz?",
          successMsg: "Dosya silindi.",
        },
      },
      product: {
        delete: {
          modalTitle: "Satın Alma Ürününü Sil",
          warningMsg: "Satın Alma ürününü silmek istediğinizden emin misiniz?",
          successMsg: "Satın Alma ürününü silindi.",
        },
        add: {
          successMsg: "Ürün kaydedildi.",
        },
        edit: {
          successMsg: "Ürün güncellendi.",
        },
        customerQuantities: {
          delete: {
            modalTitle: "Delete Pof Customer Quantity",
            warningMsg:
              "Are you sure you want to delete the pof product customer quantity?",
            successMsg: "Pof Customer Quantity deleted",
          },
          add: {
            successMsg: "",
          },
          edit: {
            successMsg: "",
          },
        },
        supplierQuantities: {
          delete: {
            modalTitle: "Delete Pof Supplier Quantity",
            warningMsg:
              "Are you sure you want to delete the pof product supplier quantity?",
            successMsg: "Pof Supplier Quantity deleted",
          },
          add: {
            successMsg: "",
          },
          edit: {
            successMsg: "",
          },
        },
      },
    },
    /** Inventory */
    inventory: {
      title: "Envanter Bilgisi",
      inventory: "Envanter",
      stockInformation: {
        SB_ARIZALI: "SB ARIZALI",
        SB_DEPO: "SB DEPO",
        ELK_DEPO: "ELK DEPO",
        ELK_ARIZALI: "ELK ARIZALI",
        SOLECTRON: "SOLECTRON",
        PENTA: "PENTA",
        NUMUNE: "NUMUNE",
        KONSINYE: "KONSİNYE",
        E_TICARET: "ETİCARET",
        TOTAL: "TOPLAM",
        ALL_WAREHOUSES: "TÜM DEPOLAR",
      },
      productAnalysis: {
        modalTitle: "Ürün Analizi",
      },
      usePriceWarningMsg:
        "Kullanmak istediğiniz alış fiyatı ile teklifinizde seçmiş olduğunuz Kur uyuşmamaktadır. Devam etmek istediğinize emin misiniz?",
    },
    /** List Prices*/
    listPrices: {
      title: "Liste Fiyatları",
      uploadListPrice: "Liste Fiyatı Yükle",
      add: {
        successMsg: "Liste Fiyatı yüklendi.",
      },
    },
    /** Special Prices*/
    specialPrices: {
      title: "Özel Fiyatlar",
      newSpecialPrice: "Yeni Özel Fiyat",
      quoteType: "Quote Türü",
      quoteDate: "Quote Tarihi",
      noProductErrorMsg:
        "Özel Fiyat ürünü oluşturulmadan özel fiyat oluşturulamaz!",
      add: {
        modalTitle: "Özel Fiyat Oluştur",
        successMsg: "Yeni özel fiyat oluşturuldu.",
      },
      edit: {
        title: "Özel Fiyatı Düzenle",
        successMsg: "Özel fiyat düzenlendi.",
      },
      delete: {
        modalTitle: "Özel Fiyatı Sil",
        warningMsg: "Özel Fiyatı silmek istediğinizden emin misiniz?",
        successMsg: "Özel Fiyat silindi.",
      },
    },
    /** Quote Tracking */
    quoteTracking: {
      title: "Teklif Takip",
      quoteOrderRate: "Tekliften Siparişe Dönüşme Oranı",
      quoteNotConvertedToOrders:"Siparişe Dönüşmemiş Teklifler",
      weekly: "Haftalık",
      monthly: "Aylık",
      quarterly: "Çeyreklik",
      yearly: "Yıllık",
      overTheLastWeek: "Son bir hafta içinde",
      overTheLastMonth: "Son bir ay içinde",
      overTheLastThreeMonth: "Son üç ay içinde",
      yearToDate: "Yıl başından itibaren",
      allSalesQuotes: "Tüm Satış/Teklifler",
      salesTeam: "Satış Ekibi",
      thirtyDays:"30 Gün",
      thirtyToFortyFiveDays:"30-45 Gün",
      overFortyFiveDays:"+45 Gün",
    },
    /** Tags */
    tags: {
      title: "Etiketler",
      warningToCreateMsg: "Lütfen yeni etiketi kayıt ediniz.",
      add: {
        successMsg: "Yeni etiket oluşturuldu.",
      },
    },
    /** Devices */
    devices: {
      title: "Cihazlar",
      roomTemperature: "Ortam Sıcaklığı",
      serverTemperature: "Yüzey Sıcaklığı",
      humidity: "Nem",
    },
    /** Announcement */
    announcement: {
      title: "Duyuru",
      deadline: "Duyuru Bitiş Tarihi",
      edit: "Duyuru Düzenleniyor",
      create: "Duyuru Oluşturuluyor",
      successMsg: "Duyuru başarıyla güncellendi.",
    },
    /**Permission Form */
    permissionForm: {
      title: "Form",
      deadline: "Form Bitiş Tarihi",
      edit: "Form Düzenleniyor",
      create: "Form Oluşturuluyor",
      successMsg: "Form başarıyla oluşturuldu.",
      chooseForm: "Form Seçiniz",
      pleaseChooseForm: "Lütfen oluşturmak istediğiniz formu seçiniz.",
      fillForm: "Formu Doldurunuz",
      leaverequestForm: "İzin Talep Formu",
      annualLeaveForm: "Senelik İzin Formu",
      excuseLeaveForm: "Mazeret İzin Formu",
    },
    /** Language */
    languages: {
      title: "Dil Tercihi",
      turkish: "Türkçe",
      english: "İngilizce",
    },
    /** Buttons */
    buttons: {
      filter: "Filtrele",
      resetFilter: "Filtreyi Sıfırla",
      submit: "Gönder",
      approve: "Onayla",
      reject: "Reddet",
      selectedApprove: "Seçilenleri Onayla",
      selectedReject: "Seçilenleri Reddet",
      close: "Kapat",
      delete: "Sil",
      deleteDevice: "Cihazı Sil",
      download: "İndir",
      downloadAll: "Hepsini İndir",
      downloadReports: "Raporları İndir",
      addReports: "Rapor Ekle",
      share: "Paylaş",
      history: "Geçmiş",
      save: "Kaydet",
      saveAll: "Tümünü Kaydet",
      saveAndClose: "Kaydet ve Kapat",
      uploadMedia: "Medya Yükle",
      remove: "Kaldır",
      follow: "Takip Et",
      unfollow: "Takibi Bırak",
      okay: "Tamam",
      repeat: "Tekrar",
      update: "Güncelle",
      cancel: "İptal",
      yes: "Evet",
      no: "Hayır",
      upload: "Yükle",
      uploadPhoto: "Fotoğraf Yükle",
      selectPhoto: "Fotoğraf Seç",
      crop: "Kırp",
      preview: "Önizle",
      send: "Gönder",
      choose: "Seç",
      change: "Değiştir",
      selectAll: "Hepsini Seç",
      clear: "Temizle",
      tryAgain: "Tekrar Dene",
      start: "Başla",
      launch: "Başlat",
      continue: "Devam Et",
      edit: "Düzenle",
      copy: "Kopyala",
      copied: "Kopyalandı",
      yesIAccept: "Evet, Onaylıyorum",
      yesIApprove: "Evet, Onaylıyorum",
      yesIReject: "Evet, Reddediyorum",
      enable2fa: "2FA Etkinleştir",
      create: "Oluştur",
      addNote: "Not Ekle",
      addPhoto: "Fotoğraf Ekle",
      reset: "Sıfırla",
      merge: "Birleştir",
      addTask: "Görev Ekle",
      detail: "Detay",
      add: "Ekle",
      switchUser: "Bu Kullanıcı Ol",
      getBack: "Geri Dön",
      addAddress: "Adres Ekle",
      confirmTransaction: "İşlemi Onayla",
      back: "Geri",
      expoReport: "Fuar Raporu",
      expoMedia: "Fuar Galerisi",
      downloadReport: "Rapor İndir",
      iReadIApprove: "Okudum, Onaylıyorum",
      confirmAndExit: "Onayla ve Çık",
      exit: "Çıkış Yap",
      deleteImage: "Resmi Sil",
      changeImage: "Resmi Değiştir",
      complete: "Tamamla",
      previous: "Geri",
      next: "İleri",
      seeMore: "Devamını Gör",
    },
    /** Phone Number Input*/
    phoneNumberInput: {
      countrySelector: {
        placeholder: "Ülke kodu",
        error: "Ülke seçin",
      },
      phoneInput: {
        placeholder: "Telefon numarası",
        example: "Örnek:",
      },
    },
    /**Badge */
    badge: {
      active: "Aktif",
      disconnected: "Bağlantı Yok",
      done: "Tamamlandı",
      passive: "Pasif",
      enabled: "Etkinleştirilmiş",
      disabled: "Devre Dışı",
      pending: "Bekliyor",
      successful: "Başarılı",
      completed: "Tamamlandı",
      notCompleted: "Tamamlanmadı",
      waitingApproval: "Onay Bekliyor",
      approved: "Onaylandı",
      notApproved: "Onaylanmamış",
    },
    /** Tooltip */
    tooltip: {
      visibleActivity: "Görünür Aktivite",
      userUpdateInfo:
        "Lütfen bu filtreyi düzenleyen filtresiyle birlikte kullanın",
      edit: "Düzenle",
      editWord: "Kelimeyi Düzenle",
      deactive: "Devre Dışı Bırak",
      changepassword: "Şifre Değiştir",
      detail: "Detay",
      delete: "Sil",
      active: "Aktif et",
      remove: "Kaldır",
      quantity: "Bir Kartta Kullanılacak Adet",
      productionPrice: "Tahmini Satış Fiyatı",
      currency: "Kur Bilgisi",
      rollingPrice: "Rolling Satış Tahmin Oranı",
      moqMrq: "Toplam Potansiyel",
      max12Char: "En Fazla 12 Karakter",
      deliveryTypeMax16Char:
        "Sarı Uyarı: PDF'de düzgün görüntü için 16 karakterden sonra alt satıra geçeceğini unutmayınız.",
      newCustomerContact: "Yeni Müşteri Kontağı Oluştur",
      newContact: "Yeni Kontak Oluştur",
      approvedAndPendingTarget: "Onaylanmış / Onay Bekleyen Hedefler",
      addRow: "Satır Ekle",
      RemoveRow: "Satır Sil",
      newProduct: "Yeni Ürün Oluştur",
      clean: "Temizle",
      newCustomer: "Yeni Müşteri Oluştur",
      criticalImportance: "Kritik Önem",
      highImportance: "Yüksek Önem",
      mediumImportance: "Orta Önem",
      lowImportance: "Düşük Önem",
      noImportance: "Önem Derecesi Belirtilmemiş",
      editNote: "Notu Düzenle",
      deleteNote: "Notu Sil",
      activity: {
        importanceOptions: {
          firstStar:
            "1 ☆ = Tüm Empa Line ekibi ve Müşterinin Satış Sorumlusu Bilgilendirilecektir.",
          secondStar:
            "2 ☆ = Tüm Empa Line ekibi, Müşterinin Satış Sorumlusu ve Tüm yöneticiler Bilgilendirilecektir.",
          thirdStar:
            "3 ☆ = Tüm Empa Line ekibi, Müşterinin Satış Sorumlusu, Tüm yöneticiler ve Tüm Satış Ekibi Bilgilendirilecektir.",
        },
      },
      showQuote: "Teklifi Görüntüle",
      showProducts: "Ürünleri Görüntüle",
      divideIntoParts: "Parçalara Böl",
      projectHistory:
        "Kaydı tutulan değişiklikler; <ul><li>Projeyi Yaratan Kişi</li><li>Projenin İsmi</li><li>Projenin Tahmini Üretim Tarihi</li><li>Ürünü Eklenmesi</li><li>Ürünün Fazı</li><li>Ürünün Register Olma Durumu</li><li>Ürünün Numune Olma Durumu</li><li>Ürünün Silinme Durumu</li><li>Projenin Silinme Durumu</li></ul>",
      quoteHistory:
        "Kaydı tutulan değişiklikler; <ul><li>Teklif Başlığı</li><li>Bütçesel mi?</li><li>Teslimat Türü</li><li>Vade</li><li>Ödeme Türü</li><li>Ürün Kodu</li><li>Ürünün Adeti</li><li>Ürünün Alış - Satış Fiyatı</li></ul>",
    },
    /** Filters */
    filters: {
      savedFilter: "Kayıtlı Filtreler",
      noSavedFilter: "Kayıtlı Filtreniz yok",
      selectFilter: "Filtre Seç",
      saveFilter: "Filtreyi Kaydet",
      updateFilter: "Filtreyi Güncelle",
      filterName: "Filtre Adı",
      successMessage: "Filtre başarıyla kaydedildi.",
      updateMessage: "Filtre başarıyla güncellendi.",
      deleteMessage: "Filtre başarıyla silindi.",
    },
    /** Chat */
    chatDrawer: {
      chatRooms: "Sohbet Odaları",
      disconnected: "Bağlantı Kesildi",
      online: "Çevrimiçi",
      typeMessage: "Mesajınızı yazınız...",
    },
    /** Changelog */
    changelog: {
      title: "Değişiklik Günlüğü",
    },
    /** Forms */
    form: {
      message: {
        required: "Bu alan boş geçilemez.",
        turkishCharacters: "Lütfen Türkçe karakter kullanınız.",
        capsLock: "Caps Lock Açık! Lütfen Caps Lock'u kapatın.",
        email: "Lütfen geçerli bir e-posta girin.",
        idNumber: "Lütfen geçerli bir kimlik numarası giriniz.",
        minLength: "Bu değer çok kısa.",
        maxLength: "Bu değer çok uzun.",
        alphaAndAlphaNum: "Yalnızca harf ve sayı kullanabilirsiniz.",
        select: "Lütfen en az 1 seçeneği seçiniz.",
        fileLength: "Lütfen en az 1 tane dosya yükleyiniz.",
        passwordMustMatch: "Şifreler aynı olmalıdır.",
        enter2FAcode: "Lütfen 6 haneli 2FA kodunuzu giriniz",
        alphaNum:
          "Lütfen sadece sayı ve harf giriniz. Türkçe karakterler kullanmayınız.",
        specialUpperandLowerCaseNumberChar:
          "Şifreniz en az bir büyük harf, bir küçük harf, bir rakam ve '{'@'}{'$'}{'%'}{'!'}' gibi özel karakterler içermeli.",
        emailDomain: "Email adresi sadece empa.com uzantılı olabilir",
        phoneNumberFormat:
          "Lütfen doğru telefon numarası girdiğinize emin olunuz.",
        deleteNote: "Not başarıyla silindi.",
        addWordSuccess: "Yeni kelime başarıyla eklendi.",
        editWordSuccess: "Kelime başarıyla güncellendi.",
        deleteWordSuccess: "Kelime başarıyla silindi.",
        emptyFields: "Lütfen aşağıda belirtilen boş alanları doldurunuz.",
        emptyProducts: "Lütfen en az bir ürün ekleyiniz.",
        valuesRequired: "Bu alanlar boş geçilemez",
        isPhaseLost: "Stage LOST olarak girildiğinde not alanı zorunludur.",
        min: "Bu alan en az {value} karakter olmalıdır.",
        max: "Bu alan en fazla {value} karakter olmalıdır.",
        issueRequest: "Sorun/istek talebi başarıyla oluşturuldu.",
        passwordRegex: "Bu alan en az bir sayı ve bir harf içermelidir.",
        url: "Lütfen linke 'https://' ile başladığınıza ve doğru linki girdiğinize emin olunuz.",
        tableRow: "Lütfen tabloya en az 1 satır ekleyiniz.",
      },
    },
    /** Table */
    table: {
      tableEmptyText: "Kayıt bulunamadı.",
      emptyFilteredText: "Talebinizle eşleşen kayıt bulunamadı.",
    },
    /** Calendar */
    calendar: {
      title: "Takvim",
      events: "Etkinlikler",
      calendarOfYearTitle: "{year} Etkinlik/Organizasyon Takvimi",
      thisMonthEventOrOrganization: "{month} Ayındaki Etkinlikler",
      newEventOrOrganization: "Yeni Etkinlik/Organizasyon",
      hasMiniCard: "Mini kart var mı?",
      cardImage: "Kart Görseli",
      add: {
        modalTitle: "Yeni Etkinlik/Organizasyon Oluştur",
        successMsg: "Yeni etkinlik/organizasyon oluşturuldu.",
      },
      edit: {
        modalTitle: "Etkinlik/Organizasyon Düzenle",
        successMsg: "Etkinlik/Organizasyon düzenlendi.",
      },
    },
    /** 404 page */
    error404info: {
      pagenotfound: "Sayfa Bulunamadı 🕵🏻‍♀️",
      backplatform: "Platforma Geri Dön",
    },
    /** Maintenance Page */
    maintenance: {
      title: "Site Is Under Maintenance 🛠",
      text: "Please check back in sometime.",
    },
    datePicker: {
      yearly: "Yearly",
      monthly: "Monthly",
      weekly: "Weekly",
      daily: "Daily",
      hourly: "Hourly",
      minutely: "Minutely",
      secondly: "Secondly",
    },
    errorCodes: {
      errorMsg: "Bir hata oluştu. Lütfen tekrar deneyiniz.",
      "500": "Sunucu hatası. Lütfen teknik ekiple iletişime geçiniz.",
      "404": "İstek başarısız oldu.",
      "400":
        "Geçerli bir seçim seçin. Bu seçim mevcut seçeneklerden biri değil.",
      "409": "İstenen kaynak mevcut",
      "40102": "Kullanıcı bulunmamaktadır.",
      "40104": "Lütfen şifrenizi kontrol ediniz.",
      "400103":
        "2FA cihazınızın kurulum tarihi sona erdiği için giriş yapamıyorsunuz. Lütfen yöneticinizle iletişime geçerek sorunu çözünüz.",
      user_inactive:
        "Hesabınız kapatılmıştır. Eğer bunun hata olduğunu düşünüyorsanız lütfen yöneticinize danışınız.",
      ERR_BAD_REQUEST: "İstek başarısız oldu.",
      firefoxBrowserErroeMsg:
        "Bu isteğinizi şu anda Firefox tarayıcı desteklememektedir. Lütfen tarayıcınızı değiştirip deneyiniz.",
    },
    swal: {
      error: {
        error: "Hata",
        title: "Bir hata oluştu.",
        buttonText: "Lütfen tekrar deneyin!",
        signInTitle: "Hatalı email ve/veya şifre.",
        headlineRequired: "Başlık alanı boş geçilemez.",
        noteContentLength: "Not içeriği en az 140 karakter olmalıdır.",
        taskDescriptionRequired:
          "Görev atarken FYA seçildiği için açıklama alanı boş geçilemez.",
        okay: "Tamam!",
        permissionDenied:
          "Bu işlemi yapmak için yetkiniz bulunmamaktadır. Lütfen yöneticiniz ile iletişime geçiniz",
      },
      success: {
        success: "Başarılı",
        title: "İşlem Başarılı!",
        copy: "Kopyalandı!",
        likeMessage: "Beğeni bilgisini güncellediniz.",
      },
      warning: {
        warning: "Uyarı",
        okay: "Tamam!",
      },
      favorites: {
        updatedFavorites: "Favoriler listeniz güncellendi.",
      },
    },
    upload: {
      componentText: "Dosyayı buraya bırakın veya {button}",
      buttonText: "yüklemek için tıklayın",
    },
    ticket: {
      title: "Sorun/İstek başarıyla oluşturuldu.",
      resolved: "Çözüldü",
      nonResolved: "Çözülmedi",
      waitingUserResponse: "Kullanıcı Cevabı Bekleniyor",
      tableEmptyText: "Talebiniz bulunmamaktadır.",
    },
    greetings: {
      morning: "Günaydın",
      afternoon: "İyi günler",
      evening: "İyi akşamlar",
    },

    filterTitle: {
      searchModule: "Ne arıyorsunuz?",
    },
    expense: {
      newExpense: "Masraf Formu Oluştur",
      title: "Masraf Formu",
      expenseVouchers: "Masraf Makbuzları",
      status: {
        DRAFT: "Taslak",
        WAITING_MANAGER_APPROVAL: "Yönetici Onayı Bekleniyor",
        MANAGER_APPROVED: "Yönetici Onayladı",
        MANAGER_REJECTED: "Yönetici Reddeti",
        WAITING_FINANCE_CONTROL: "Finans Kontrolü Bekleniyor",
        FINANCE_APPROVED: "Finans Onayladı",
        FINANCE_REJECTED: "Finans Reddetti",
        COMPLETED: "Tamamlandı",
      },
      add: {
        title: "Masraf Formu Oluştur",
        successMsg: "Masraf Formu oluşturuldu.",
      },
      edit: {
        title: "Masraf Formunu Düzenle",
        successMsg: "Masraf Formu düzenlendi.",
      },
      delete: {
        modalTitle: "Masraf Formunu Sil",
        warningMsg: "Masraf Formunu silmek istediğinizden emin misiniz?",
        successMsg: "Masraf Formunu silme isteğiniz alınmıştır.",
      },
      confirm: {
        modalTitle: "Masraf Formunu Onayla",
        warningMsg: "Masraf Formunu onaylamak istediğinize emin misiniz?",
        successMsg: "Masraf Formu onaylandı.",
      },
      cancel: {
        modalTitle: "Masraf Formunu İptal Et",
        cancellationReason: "İptal Nedeni",
        warningMsg: "Masraf Formunu iptal etmek istediğinize emin misiniz?",
        successMsg: "Masraf Formu iptal edildi.",
      },
      expenseVoucher: {
        add: {
          successMsg: "Masraf Fişi oluşturuldu.",
        },
        edit: {
          successMsg: "Masraf Fişi düzenlendi.",
        },
        delete: {
          modalTitle: "Masraf Fişi silindi",
          warningMsg: "Masraf Fişini silmek istediğinizden emin misiniz?",
          successMsg: "Masraf Fişi silinmiştir.",
        },
      },
    },
    kpiManagement: {
      title: "KPI Yönetim Paneli",
    },
    kpiMarketingManagement: {
      title: "KPI Pazarlama Yönetimi",
      lineTargets: {
        title: "Line Hedefleri",
        tableHeader: {
          line: "Üretici",
          newSocketCount: "Yeni Soket Hedef Sayısı",
          newSocketAmount: "Yeni Soket Hedefi (kUSD)",
          newCustomerCount: "Yeni Müşteri Adeti (Çeyreklik)",
        },
        success: {
          title: "Başarılı",
          message: "Girdiğiniz veriler başarıyla kaydedilmiştir.",
        },
      },
      userTargets: {
        title: "Kullanıcı Hedefleri",
      },
    },
    logoCustomers: {
      title: "Logo Müşterileri",
      currencyMessage:
        "Lütfen oluşturmak istediğiniz cari hesaba ait kur hesaplarını seçiniz.",
      status: {
        IN_PROGRESS: "Devam Ediyor",
        WAITING_FOR_APPROVAL: "Onay Bekleniyor",
        REVISION_REQUIRED: "Revizyon Gerekli",
        APPROVED: "Onaylandı",
        DRAFT: "Taslak",
        UPDATE_REQUESTED: "Güncelleme İsteği",
        CANCELLED: "İptal Edildi",
      },
      edit: {
        title: "Yeni Müşteri Formu",
        contactInfo: "İletişim Bilgileri",
        commercialInfo: "Ticari Bilgileri",
        successMsg: "Müşteri Logo'ya başarıyla kaydedildi.",
        fileErrorMsg: "Lütfen önce vergi levhası dosyası giriniz.",
        successSaveMsg: "Müşteri başarıyla kaydedildi.",
      },
      rejectModal: {
        title: "Güncelleme İsteme Sebebi",
        reason: "Lütfen güncelleme sebebini yazınız.",
      },
    },
    logoProducts: {
      title: "Logo Ürünleri",
      status: {
        IN_PROGRESS: "Devam Ediyor",
        WAITING_FOR_APPROVAL: "Onay Bekleniyor",
        REVISION_REQUIRED: "Revizyon Gerekli",
        APPROVED: "Onaylandı",
        DRAFT: "Taslak",
        UPDATE_REQUESTED: "Güncelleme İsteği",
        CANCELLED: "İptal Edildi",
      },
      edit: {
        title: "Yeni Logo Ürünü",
        kdv: {
          kdvRatio: "KDV Oranları",
          purchasing: "Satın Alma",
          sales: "Satış",
          retail: "Perakende Satış",
          rebate: "İade",
          retailRebate: "Perakende İade",
          kdv20: "Hepsine 20% Uygula",
        },
        gtipCode: "GTIP Kodu",
        createGtip: {
          title: "Yeni GTIP Oluştur",
          customsDuty: "Gümrük Vergisi",
          additionalCustomsDuty: "Ek Gümrük Vergisi",
          additionalFinancialObligation: "Ek Finansal Yükümlülük",
          additionalFinancialObligationUsa: "Ek Finansal Yükümlülük ABD",
          kkdf: "KKDF",
          hasTarexControl: "Tarex kontrolü var mı?",
          hasImeiControl: "IMEI Kontrolü var mı?",
        },
        successMsg: "Ürün Logo'ya başarıyla kaydedildi.",
        successSaveMsg: "Ürün başarıyla kaydedildi.",
      },
      rejectModal: {
        title: "Ürünü Güncelleme İsteği",
        reason: "Lütfen güncelleme isteme sebebini yazınız.",
      },
    },
    tasks: {
      title: "Görevler",
      myTasks: "Görevlerim",
      assignedByMe: "Atadıklarım",
      assignee: "Atanan Kişi",
      viewProject: "Projeyi Görüntüle",
      viewActivity: "Aktiviteyi Görüntüle",
      viewQuote: "Teklifi Görüntüle",
      modules: {
        activity: "Aktivite",
        quote: "Teklif",
        project: "Proje",
      },
      complete: {
        modalTitle: "Görevi Tamamla",
        successMsg: "Görev tamamlandı.",
      },
      edit: {
        modalTitle: "Görevi Düzenle",
        successMsg: "Görev düzenlendi.",
      },
      delete: {
        modalTitle: "Görevi Sil",
        warningMsg: "Görevi silmek istediğinizden emin misiniz?",
        successMsg: "Görev silindi.",
      },
    },
  },
};

let locale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

locale = locale.includes("-") ? locale.split("-")[0] : locale;

const languages = ["en", "tr"];

if (!window.localStorage.getItem("lang")) {
  window.localStorage.setItem(
    "lang",
    languages.includes(locale) ? locale : "tr"
  );
}
const currentLang = window.localStorage.getItem("lang");

const i18n = createI18n({
  legacy: false,
  locale: currentLang ? currentLang : "tr",
  globalInjection: true,
  messages,
});

export default i18n;
