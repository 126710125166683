import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";

export const useFirebaseStore = defineStore("firebase", () => {
  async function saveFirebaseInfo(datas) {
    try {
      const response = await ApiService.post(
        `/api/notification/devices/`,
        datas
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    saveFirebaseInfo,
  };
});
